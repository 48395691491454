import styled from "@emotion/styled";
import classNames from "classnames";
import React from "react";

const StyledContainer = styled.div`
    max-width: 1054px;
    width: 50%;

    flex-shrink: 0;

    user-select: none;

    position: absolute;
    top: 0;
    left: 0;

    transform-origin: center;

    height: 100%;

    will-change: transform;
    @media screen and (max-width: 1055px) {
        display: none;
    }

    .line {
        position: relative;
        top: calc(50% - 1px);
        left: 0%;

        width: 100%;
        height: 2px;
        background: #000;
        will-change: transform;
        .dark-mode & {
            background: #fff;
        }

        transform: scaleX(0);
    }
`;

const LineView = ({ step }) => {
    return (
        <StyledContainer
            className={classNames("line-container")}
            data-step={step}
        >
            <div className="line"></div>
        </StyledContainer>
    );
};

export default React.memo(LineView);
