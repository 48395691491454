import styled from "@emotion/styled";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { atoms, selectors } from "../../../recoil/atom";
import Chip from "./Chip";

const ChipsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    position: relative;

    @media screen and (max-width: 1055px) {
        display: grid;
        grid-template-columns: repeat(2, minmax(50%, 50%));
        grid-auto-rows: 1fr;
    }
`;

const MultiSelectChip = ({ selects, vertical, coilKey, startTabIndex }) => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const [checks, setChecks] = useRecoilState(atoms[coilKey]);

    const _handleChange = (value, isChecked) => {
        if (isChecked) {
            setChecks((checks) => [...checks, value]);
        } else if (!isChecked && checks.indexOf(value) !== -1) {
            setChecks((checks) => [...checks.filter((c) => c !== value)]);
        }
    };

    return (
        <ChipsContainer className="chip-container">
            {Object.keys(selects).map((select, i) => (
                <Chip
                    id={selects[select].id}
                    key={select}
                    name={coilKey}
                    value={select}
                    isChecked={checks.indexOf(select) !== -1}
                    text={selects[select].text[lang]}
                    handleChange={_handleChange}
                    vertical={vertical}
                    data-tabindex={i + 1 + startTabIndex || i + 1}
                    tooltipText={
                        selects[select].tooltipText &&
                        selects[select].tooltipText[lang]
                    }
                />
            ))}
        </ChipsContainer>
    );
};

export default React.memo(MultiSelectChip);
