import styled from "@emotion/styled";
import classNames from "classnames";
import React from "react";

const StyledContainer = styled.div`
    &.touch-screen {
        display: none;
    }

    position: absolute;
    z-index: 10;

    font-size: 14px;
    line-height: 1.4;
    font-weight: 400;

    bottom: 10px;
    transform: translate(0, 100%);

    opacity: 0;

    transition: opacity 0.2s cubic-bezier(0.33, 1, 0.68, 1);

    pointer-events: none;

    max-width: 130%;
    min-width: 100%;
    width: auto;

    word-break: keep-all;
    word-wrap: break-word;

    @media screen and (max-width: 1500px) {
        font-size: 12px;
    }

    @media screen and (max-width: 1055px) {
        bottom: 7px;
    }

    .text-box {
        position: relative;

        padding: 15px;
        text-align: left;

        background: #fff;
        border: 2px solid #000;
        border-radius: 16px;

        .current & {
            box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.15);
        }

        @media screen and (max-width: 1055px) {
            padding: 10px;
            border-radius: 10px;

            font-size: 12px;
        }
    }

    .arrow {
        border: 2px solid #000;
        background: #fff;
        border-right: none;
        border-bottom: none;

        width: 11px;
        height: 11px;

        top: 0;
        left: 50%;

        transform-origin: center;
        transform: translate(-50%, -50%) rotate(45deg);
        position: absolute;
        top: 0;
    }
`;

const Tooltip = ({ text }) => {
    return (
        <StyledContainer
            className={classNames(
                "tooltip",
                "ontouchstart" in document.documentElement && "touch-screen"
            )}
        >
            <div className="text-box">{text}</div>
            <div className="arrow"></div>
        </StyledContainer>
    );
};

export default React.memo(Tooltip);
