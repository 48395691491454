import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { useRecoilState } from "recoil";
import { atoms } from "../../recoil/atom";
import classNames from "classnames";

const StyledTextarea = styled.div`
    margin: 4px;

    .textarea-wrapper {
        border-radius: 34px;

        padding: 1.9vw;

        min-width: 40.5vw;
        width: 100%;
        height: 20.1vw;

        background: #fff;

        transition-property: box-shadow, background-color;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);

        .current & {
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
        }

        &:active {
            box-shadow: none;
        }

        &:hover {
            background: #fafafa;
        }

        &.focused {
            background: #000;
            textarea {
                color: #fff;
            }
            textarea::selection {
                background: #fff;
                color: #000;
            }
        }

        @media screen and (max-width: 1300px) {
            padding: 25px;
        }

        @media screen and (max-width: 1055px) {
            border-radius: 17px;

            width: 100%;
            height: 200px;

            font-size: 12px;
        }
    }

    textarea {
        color: #000;
        font-weight: 700;
        line-height: 1.6;

        background: transparent;

        width: 100%;
        height: 100%;

        border: none;
        outline: none;
        resize: none;

        font-size: 18px;

        transition-property: color;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);

        &::-webkit-scrollbar {
            width: 10px;
            background: transparent;

            @media screen and (max-width: 1055px) {
                width: 6px;
            }
        }

        &::-webkit-scrollbar-thumb {
            background: #ccc;
            border-radius: 6px;
            margin-right: 60px;
        }

        &::-webkit-scrollbar-track {
            margin: 34px 0;
        }

        @media screen and (max-width: 1500px) {
            font-size: 12px;
        }

        &::placeholder {
            color: #ccc;

            font-weight: 700;
            line-height: 1.8;
        }
    }
`;

const Textarea = ({ placeholder, coilKey, name }) => {
    const [state, setState] = useRecoilState(atoms[coilKey]);
    const [isFocused, setIsFocused] = useState(false);
    const ref = useRef(null);

    const _handleChange = (e) => {
        const newContent = e.target.value.replace(/\n/g, "\n");

        setState({
            ...state,
            [name]: newContent,
        });
    };

    const handleFocus = (e) => {
        setIsFocused(true);
    };

    const handleBlur = (e) => {
        setIsFocused(false);
    };

    return (
        <StyledTextarea>
            <div
                className={classNames(
                    "textarea-wrapper",
                    isFocused && "focused"
                )}
            >
                <textarea
                    ref={ref}
                    placeholder={placeholder}
                    onChange={_handleChange}
                    value={state.text}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    spellCheck={false}
                ></textarea>
            </div>
        </StyledTextarea>
    );
};

export default Textarea;
