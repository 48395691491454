import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { theme } from "../../theme/";
import { useRecoilValue } from "recoil";
import { selectors } from "../../recoil/atom";
import useReset from "../../hooks/useReset";
import useValidation from "../../hooks/useValidation";

const StyledHeader = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    z-index: 500;

    width: 100%;

    padding: calc(
            ${theme["normal"].paddingVertical.value +
                theme["normal"].paddingVertical.unit} - 15px
        )
        ${theme["normal"].paddingHorizontal.value +
        theme["normal"].paddingHorizontal.unit}
        0;

    opacity: 0;
    visibility: hidden;
    @media screen and (max-width: 1055px) {
        padding: calc(
                ${theme["1055"].paddingVertical.value +
                    theme["1055"].paddingVertical.unit} - 15px
            )
            ${theme["1055"].paddingHorizontal.value +
            theme["1055"].paddingVertical.unit}
            0;
    }

    .header-wrapper {
        width: 100%;
        display: flex;

        justify-content: space-between;
    }

    .logo-container {
        display: flex;
        align-items: center;

        cursor: pointer;

        outline: none;
        border: none;
        background: transparent;

        color: #000;

        padding: 25px;
        padding-left: 0;

        transition: color 0.5s;

        .dark-mode & {
            color: #fff;
        }

        .icon-container {
            width: 80px;
            height: 40px;

            @media screen and (max-width: 1055px) {
                width: 54px;
                height: 27px;
            }

            display: flex;

            margin-right: 25px;

            span {
                position: relative;

                display: block;

                width: 50%;
                height: 100%;

                transform-origin: center;
                left: 0;

                &::after,
                &::before {
                    content: "";
                    display: inline-block;

                    position: absolute;
                    left: 0;
                    top: 0;

                    width: calc(141% - 1px);
                    height: 2px;

                    top: calc(50% - 1px);
                    left: 50%;

                    transform-origin: center;

                    background-color: currentColor;
                }

                &::after {
                    transform: translateX(-50%) rotate(-45deg);
                }

                &::before {
                    transform: translateX(-50%) rotate(45deg);
                }

                &:nth-of-type(1) {
                    transform: rotate(45deg);
                    &::after,
                    &::before {
                        width: 100%;
                    }
                }
            }
        }

        &.hover-logo {
            span {
                transition: left 0.4s cubic-bezier(0.215, 0.61, 0.355, 1),
                    transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;

                &:first-of-type {
                    left: 50%;
                    transform: rotate(180deg);

                    &::after,
                    &::before {
                        width: calc(141% - 1px);
                        transition: width 0.3s linear 0.4s;
                    }
                }

                &:nth-of-type(2) {
                    left: -50%;
                    transform: rotate(-135deg);
                    transition: left 0.4s cubic-bezier(0.215, 0.61, 0.355, 1),
                        transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s;

                    &::after,
                    &::before {
                        width: 100%;
                        transition: width 0.3s linear 0.25s;
                    }
                }
            }
        }

        .logo-text {
            text-align: left;

            font-size: 18px;
            font-weight: 500;
            line-height: 1.1;

            user-select: none;

            @media screen and (max-width: 1055px) {
                display: none;
            }
        }
    }

    .lang {
        display: flex;
        align-items: center;

        user-select: none;
        display: none;

        .lang-item {
            transition: color 0.5s;
        }

        .lang-item:not(.lang-line) {
            font-size: 28px;
            line-height: 1.2;
            font-weight: 700;

            cursor: pointer;

            outline: none;
            border: none;
            background: transparent;
            color: #ccc;

            transition: color 0.2s;

            .dark-mode & {
                color: #333;
            }

            padding: 25px;
            @media screen and (max-width: 1055px) {
                padding: 12px;
            }
            &.lang-kr {
                padding-right: 0;
            }
            &.lang-en {
                padding-left: 0;
            }

            [lang="kr"] .dark-mode &.lang-kr,
            [lang="en"] .dark-mode &.lang-en {
                color: #fff;
            }
            @media screen and (max-width: 1055px) {
                font-size: 20px;
            }
        }

        [lang="kr"] & .lang-kr,
        [lang="en"] & .lang-en {
            color: #000;
            .dark-mode & {
                color: #fff;
            }
        }

        .lang-item:not(.lang-kr) {
        }

        .lang-line {
            margin: 0 16px;
        }
    }
`;

const Header = ({ handleLanguage }) => {
    const { toHome } = useReset();
    const { nextActiveCondition } = useValidation();

    const isSlideAnimating = useRecoilValue(selectors["getIsSlideAnimating"]);

    const logoRef = useRef(null);

    const handleReset = () => {
        if (
            !document.querySelector(".swiper-wrapper").dataset.moving &&
            !isSlideAnimating
        ) {
            toHome();
            nextActiveCondition[0][0]();
        }
    };

    useEffect(() => {
        const mouseEnterHandler = function () {
            this.classList.add("hover-logo");
        };
        logoRef.current.addEventListener("mouseenter", mouseEnterHandler);

        const transitionHandler = function (e) {
            if (e.propertyName === "transform") {
                this.classList.remove("hover-logo");
            }
        };
        logoRef.current.addEventListener("transitionend", transitionHandler);

        const ref = logoRef.current;
        return () => {
            ref.removeEventListener("mouseenter", mouseEnterHandler);
            ref.removeEventListener("transitionend", transitionHandler);
        };
    });

    return (
        <StyledHeader className="header-container">
            <div className="header-wrapper">
                <button
                    className="logo-container"
                    onClick={handleReset}
                    ref={logoRef}
                >
                    <div className="icon-container">
                        <span></span>
                        <span></span>
                    </div>
                    <div className="logo-text">
                        <div>Contact me</div>
                        <div>anytime.</div>
                    </div>
                </button>
                <div className="lang" onClick={handleLanguage}>
                    <button className="lang-item lang-kr">KR</button>
                    <span className="lang-item lang-line">/</span>
                    <button className="lang-item lang-en">EN</button>
                </div>
            </div>
        </StyledHeader>
    );
};

export default React.memo(Header);
