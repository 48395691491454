import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import classNames from "classnames";
import Tooltip from "../../Tooltip";
import { useRecoilValue } from "recoil";
import { selectors } from "../../../recoil/atom";
import gsap from "gsap";

const transitionOutDurPc = 0.15;
const transitionOutDurMobile = 0.15;

const delayTerm = 0.1;

const ChipContainer = styled.div`
    margin: 2px;

    @media screen and (max-width: 1055px) {
        margin: 2.5px;
        &:nth-of-type(2n) {
            margin-right: 0;
        }
        &:nth-of-type(2n + 1) {
            margin-left: 0;
        }
    }

    .chip {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        background: #fff;

        transition-property: color, background-color, box-shadow;
        transition-duration: ${transitionOutDurPc}s;

        transition-delay: 0s;

        cursor: pointer;

        font-weight: 700;
        font-size: 18px;
        line-height: 1.25;
        letter-spacing: -0.03em;

        text-align: center;

        user-select: none;

        word-break: keep-all;
        word-wrap: break-word;

        .current & {
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
        }

        @media screen and (max-width: 1500px) {
            font-size: 12px;
        }

        @media screen and (max-width: 1055px) {
            transition-duration: ${transitionOutDurMobile}s;
        }

        .text-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 100%;

            overflow: hidden;

            span {
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        & .after {
            box-sizing: content-box;

            background-color: #fff;
            background-image: url(/svg/check.svg);
            background-repeat: no-repeat;
            background-position: center;

            position: absolute;
            top: 5px;
            right: 5px;

            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 50%;
            width: 1.7vw;
            height: 1.7vw;

            border-width: 3px;
            border-style: solid;
            border-color: #000;
            background-size: 50% 50%;

            opacity: 0;
            transform-origin: center;
            transform: scale(0.75);

            @media screen and (max-width: 1055px) {
                width: 16px;
                height: 16px;
                background-size: 50% 50%;

                border-width: 2px;

                top: 0;
                right: 0;
            }
        }

        &:hover {
            background: #fafafa;
            .tooltip {
                pointer-events: auto;
                opacity: 1;

                transition-property: opacity;
                transition-duration: 0.2s;
                transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
            }
        }

        width: 13.4vw;
        height: 6.25vw;
        border-radius: 3.1vw;
        padding: 1.2em 1.1em;
        &.vertical {
            width: 7vw;
            height: 12.5vw;

            border-radius: 8.85vw;

            .text-wrapper {
                span {
                    -webkit-line-clamp: 4;

                    @media screen and (max-width: 1055px) {
                        -webkit-line-clamp: 2;
                    }
                }
            }
        }
        @media screen and (max-width: 1055px) {
            &,
            &.vertical {
                width: 100%;
                height: 100%;

                font-size: 12px;
                line-height: 18px;
                border-radius: 170px;
                padding: 13px;
            }
        }

        &:active {
            box-shadow: none;
        }

        input {
            width: 0;
            height: 0;
            opacity: 0;
        }
    }
`;

const Chip = ({
    id,
    name,
    value,
    text,
    handleChange,
    vertical,
    tooltipText,
    isChecked,
    ...rest
}) => {
    const chip = useRef(null);
    const isMobile = useRecoilValue(selectors["getIsMobileWidth"]);
    const [hasTransition, setHasTransition] = useState(true);

    const _handleChange = ({ target }) => {
        handleChange(value, target.checked);
        const checkIcon = chip.current.querySelector(".after");
        const bg = chip.current;
        const text = chip.current.querySelector(".text-wrapper span");

        const scaleDur = isMobile ? 0.3 : 0.3;

        const tl = gsap.timeline({
            onComplete() {
                setHasTransition(true);
            },
        });
        if (target.checked) {
            setHasTransition(false);
            gsap.killTweensOf([bg, checkIcon, text]);
            tl.addLabel("start")
                .to(
                    bg,
                    {
                        background: "#000",
                        duration: 0.2,
                        ease: "power1.inOut",
                    },
                    "start"
                )
                .to(
                    text,
                    {
                        color: "#fff",
                        duration: 0.2,
                        ease: "power1.inOut",
                    },
                    "start"
                )
                .addLabel("check", `start+=${delayTerm}s`)
                .fromTo(
                    checkIcon,
                    {
                        opacity: 0,
                    },
                    {
                        opacity: 1,
                        duration: 0.2,
                        ease: "power2.out",
                    },
                    "check"
                )
                .fromTo(
                    checkIcon,
                    { scale: 0.75 },
                    {
                        scale: 1,
                        duration: scaleDur,
                        ease: "power2.out",
                    },
                    "check"
                );
        } else {
            gsap.killTweensOf([bg, checkIcon, text]);
            tl.addLabel("start")
                .to(
                    checkIcon,
                    {
                        opacity: 0,
                        duration: 0.15,
                        ease: "power2.out",
                    },
                    "start"
                )
                .to(
                    bg,
                    {
                        background: "#fff",
                        ease: "power1.out",
                        duration: 0.05,
                    },
                    "start"
                )
                .to(
                    text,
                    {
                        color: "#000",
                        ease: "power1.out",
                        duration: 0.05,
                    },
                    "start"
                )
                .set(
                    checkIcon,
                    {
                        scale: 0.75,
                    },
                    ">+=.5"
                )
                .set([bg, text], {
                    clearProps: "all",
                });
        }
    };

    return (
        <ChipContainer>
            <label
                ref={chip}
                htmlFor={id}
                className={classNames(
                    "chip",
                    "multi-chip",
                    isChecked && "selected",
                    vertical && "vertical",
                    !hasTransition && "no-transition"
                )}
            >
                {tooltipText && <Tooltip text={tooltipText} />}
                <input
                    id={id}
                    type="checkbox"
                    name={name}
                    value={value}
                    checked={isChecked}
                    onChange={_handleChange}
                    {...rest}
                />
                <div className="text-wrapper">
                    <span>{text}</span>
                </div>
                <span className="after"></span>
            </label>
        </ChipContainer>
    );
};

export default React.memo(Chip);
