import { useState, useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { atoms, selectors } from "../recoil/atom";
import gsap from "gsap";
import { theme } from "../theme";

import { PhoneNumberUtil } from "google-libphonenumber";

const useValidation = () => {
    const isMobile = useRecoilValue(selectors["getIsMobileWidth"]);

    const [isShowing, setIsShowing] = useState(false);
    const [, setNextDisable] = useRecoilState(atoms["nextDisabled"]);

    // step1
    const reqType = useRecoilValue(selectors["getRequestType"]);

    // step2
    const requester = useRecoilValue(selectors["getRequesterInfo"]);

    // step3
    const requestProject = useRecoilValue(selectors["getRequestProject"]);

    // Project step4
    const brandRange = useRecoilValue(selectors["getRequestRangeBrand"]);
    const UIRange = useRecoilValue(selectors["getRequestRangeUI"]);

    // Project step5
    const projectRange = useRecoilValue(selectors["getRequestRangeProject"]);

    // Project step6
    const startDue = useRecoilValue(selectors["getStartDue"]);
    const endDue = useRecoilValue(selectors["getEndDue"]);
    const scheduleAdjust = useRecoilValue(selectors["getScheduleAdjust"]);

    // Project step7
    const price = useRecoilValue(selectors["getRequestPrice"]);

    // Request step4
    const kindRange = useRecoilValue(selectors["getRequestRangeKind"]);

    const requestTypeValidation = useCallback(() => {
        // Step 1
        if (reqType === -1) {
            setNextDisable(true);
            return;
        }
        window.onbeforeunload = function () {
            return "";
        };
        setNextDisable(false);
    }, [reqType, setNextDisable]);

    const requesterValidation = useCallback(() => {
        // step2
        let res = false;
        let reset = false;

        // 이름
        if (requester.name.trim() === "") {
            res = true;
            reset = true;
        }

        if (!reset) {
            document
                .querySelector("input[name='name']")
                .classList.remove("error");
        }

        // 회사
        reset = false;
        if (requester.company.trim() === "") {
            res = true;
            reset = true;
        }

        if (!reset) {
            document
                .querySelector("input[name='company']")
                .classList.remove("error", "error-with-text");
        }

        // 이메일
        const emailReg =
            /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        reset = false;

        if (requester.email.trim() === "") {
            res = true;
            reset = true;
        }

        if (!reset) {
            document
                .querySelector("input[name='email']")
                .classList.remove("error");
        }

        if (requester.email.trim() !== "" && !emailReg.test(requester.email)) {
            res = true;
            reset = true;
        }

        if (!reset) {
            document
                .querySelector("input[name='email']")
                .classList.remove("error-with-text");
        }

        // 연락처
        const phoneUtil = PhoneNumberUtil.getInstance();

        reset = false;

        if (requester.contact.trim() === "") {
            res = true;
            reset = true;
        }

        if (!reset) {
            document
                .querySelector("input[name='contact']")
                .classList.remove("error");
        }

        let phone;

        reset = false;
        try {
            phone = phoneUtil.parse(requester.contact, "KR");

            const val = phoneUtil.isValidNumberForRegion(phone, "kr");
            if (!val) {
                res = true;
                reset = true;
            } else {
                reset = false;
            }
        } catch (e) {
            res = true;
            reset = true;
        }

        if (!reset) {
            document
                .querySelector("input[name='contact']")
                .classList.remove("error-with-text");
        }

        setNextDisable(res);
    }, [requester, setNextDisable]);

    const requesterAction = useCallback(() => {
        requesterValidation();
        const tl = gsap.timeline();
        gsap.killTweensOf(".back-container");
        tl.delay(
            isMobile ? theme.stepButtonDelay.mobile : theme.stepButtonDelay.pc
        )
            .set(
                ".back-container",
                {
                    display: "flex",
                },
                ">"
            )
            .set(
                ".step-container",
                {
                    justifyContent: "space-between",
                },
                ">"
            )
            .to(".back-container", {
                opacity: 1,
                ease: "power1.inOut",
                duration: theme.buttonOpDur,
            });
    }, [requesterValidation, isMobile]);

    const projectNameValidation = useCallback(() => {
        // 프로젝트 이름 step3
        let res = false;

        if (requestProject.projectName.trim() === "") {
            res = true;
        }

        setNextDisable(res);
    }, [requestProject, setNextDisable]);

    const requestNameValidation = useCallback(() => {
        // 의뢰 이름 step3
        let res = false;
        if (requestProject.requestName.trim() === "") {
            res = true;
        }

        setNextDisable(res);
    }, [requestProject, setNextDisable]);

    const brandUIRangeValidation = useCallback(() => {
        // 프로젝트 step4
        if (brandRange.length === 0 && UIRange.length === 0) {
            setNextDisable(true);

            return;
        }

        setNextDisable(false);
    }, [brandRange, UIRange, setNextDisable]);

    const projectRangeValidation = useCallback(() => {
        // project step5
        if (projectRange.length === 0) {
            setNextDisable(true);

            return;
        }

        setNextDisable(false);
    }, [projectRange, setNextDisable]);

    // 예상 기간
    const dueValidation = useCallback(() => {
        // Project step6
        const startDate = new Date(`${startDue.year}-${startDue.month}`);
        const endDate = new Date(`${endDue.year}-${endDue.month}`);

        if (startDate >= endDate) {
            if (startDate <= endDate) {
                // 년 월 같을 때
                if (endDue.due - startDue.due > 0) {
                    setNextDisable(false);
                    return;
                }
            }
            setNextDisable(true);
            return;
        }

        if (scheduleAdjust === -1) {
            setNextDisable(true);
            return;
        }

        setNextDisable(false);
    }, [startDue, endDue, scheduleAdjust, setNextDisable]);

    const priceValidation = useCallback(() => {
        // project step7
        if (price === -1) {
            setNextDisable(true);

            return;
        }

        setNextDisable(false);
    }, [price, setNextDisable]);

    const kindRangeValidation = useCallback(() => {
        // request step4
        if (kindRange === -1) {
            setNextDisable(true);

            return;
        }
        setNextDisable(false);
    }, [kindRange, setNextDisable]);

    const additionalInfoValidation = useCallback(() => {
        // request step5, project step8
        setNextDisable(false);
    }, [setNextDisable]);

    const showChoise = useCallback(() => {
        if (!isShowing) {
            setIsShowing(true);

            const tl = gsap.timeline();
            const chipContainer = document.querySelector(
                ".choise-chip > .chip-container"
            );
            const dividerContainer =
                chipContainer.querySelector(".divider-container");
            const divider = chipContainer.querySelector(".divider");
            const choiseText = chipContainer.querySelector(".choise-text");
            const samllChip =
                chipContainer.querySelectorAll(".small-chip > div");
            gsap.killTweensOf([chipContainer, samllChip]);

            const isFullDivider = window.innerWidth <= 550;

            const scaleDur = isFullDivider ? 1 : 0.8;
            const scaleEase = "power2.inOut";

            const moveDur = isFullDivider ? 1 : 0.8;
            const moveEase = "power2.inOut";

            const hasResponse = scheduleAdjust !== -1;

            if (!hasResponse) {
                tl.set(divider, {
                    scaleX: 0,
                }).set(choiseText, {
                    x: `${dividerContainer.clientWidth}px`,
                });
            }

            tl.addLabel("start")
                .to(
                    [choiseText, samllChip],
                    {
                        autoAlpha: 1,
                        duration: isMobile ? 0.5 : 0.65,
                        ease: "power1.inOut",
                        delay: isMobile ? 1.4 : 1.7,
                        stagger: {
                            amount: !hasResponse ? 0.04 : 0,
                            onComplete() {
                                const elem = this._targets[0];
                                if (
                                    elem.classList.contains("chip-wrapper") &&
                                    hasResponse
                                ) {
                                    gsap.set(elem, {
                                        pointerEvents: "auto",
                                    });
                                }
                            },
                        },
                    },
                    "start"
                )
                .to(
                    divider,
                    {
                        autoAlpha: 1,
                        duration: isMobile ? 0.5 : 0.65,
                        ease: "power1.inOut",
                        delay: isMobile ? 1.4 : 1.7,
                    },
                    "start"
                );

            if (!hasResponse) {
                tl.addLabel("scale", ">")
                    .to(
                        divider,
                        {
                            scaleX: 1,
                            ease: scaleEase,
                            duration: scaleDur,
                        },
                        "scale"
                    )
                    .to(
                        choiseText,
                        {
                            x: 0,
                            ease: moveEase,
                            duration: moveDur,
                        },
                        "scale"
                    );
            }
            tl.set(
                samllChip,
                {
                    pointerEvents: "auto",
                },
                ">"
            );
        }
    }, [isMobile, scheduleAdjust, isShowing]);

    const hideChoise = useCallback(() => {
        setIsShowing(false);
        const tl = gsap.timeline();
        const chipContainer = document.querySelector(
            ".choise-chip > .chip-container"
        );
        const dividerContainer =
            chipContainer.querySelector(".divider-container");
        const divider = chipContainer.querySelector(".divider");
        const choiseText = chipContainer.querySelector(".choise-text");
        const samllChip = chipContainer.querySelectorAll(".small-chip > div");
        gsap.killTweensOf([chipContainer, divider, choiseText, samllChip]);

        tl.addLabel("start")
            .to(
                [choiseText, samllChip],
                {
                    autoAlpha: 0,
                    duration: isMobile ? 0.5 : 0.5,
                    ease: "power1.inOut",
                    onStart() {
                        gsap.set(samllChip, {
                            pointerEvents: "none",
                        });
                    },
                },
                "start"
            )
            .to(
                divider,
                {
                    autoAlpha: 0,
                    duration: isMobile ? 0.5 : 0.5,
                    ease: "power1.inOut",
                },
                "start"
            );

        if (scheduleAdjust === -1) {
            tl.set(divider, {
                scaleX: 0,
            }).set(choiseText, {
                x: `${dividerContainer.clientWidth}px`,
            });
        }
    }, [isMobile, scheduleAdjust]);

    const mainAction = useCallback(() => {
        document.body.classList.remove("final-view");

        const tl = gsap.timeline();

        tl.to(".home-container", {
            opacity: 0,
            ease: "power1.inOut",
            duration: theme.buttonOpDur,
        })
            .set(".home-container", {
                display: "none",
            })
            .set(".step-container", {
                justifyContent: "space-between",
            })
            .to(".back-container", {
                opacity: 0,
                ease: "power1.inOut",
                duration: theme.buttonOpDur,
            })
            .addLabel("backDisappear")
            .set(
                ".back-container",
                {
                    display: "none",
                },
                "backDisappear+=.3"
            )
            .set([".back", ".back ~ .border-clone"], {
                display: "block",
                opacity: 1,
            })
            .set(".step-container", {
                justifyContent: "flex-end",
            })
            .set(".next-container", {
                display: "flex",
                opacity: 1,
            })
            .set([".next", ".next ~ .border-clone"], {
                display: "block",
            })
            .to([".next", ".next ~ .border-clone"], {
                opacity: 1,
                ease: "power1.inOut",
                duration: theme.buttonOpDur,
            })
            .call(setNextDisable, [true]);
    }, [setNextDisable]);

    const requestTypeAction = useCallback(() => {
        requestTypeValidation();
        const tl = gsap.timeline();

        if (document.querySelector(".swiper-wrapper").dataset.moving) {
            gsap.killTweensOf([".back-container", ".step-container"]);
        }

        tl.to(".back-container", {
            opacity: 0,
            ease: "power1.inOut",
            duration: theme.buttonOpDur,
        })
            .addLabel("backDisappear")
            .set(
                ".step-container",
                {
                    justifyContent: "flex-end",
                },
                "backDisappear+=.2"
            )
            .set(
                ".back-container",
                {
                    display: "none",
                },
                "backDisappear+=.3"
            );
    }, [requestTypeValidation]);

    const additionalInfoAction = useCallback(() => {
        additionalInfoValidation();
        gsap.killTweensOf(".next-container");

        const tl = gsap.timeline();

        tl.delay(
            isMobile ? theme.stepButtonDelay.mobile : theme.stepButtonDelay.pc
        )
            .set(".next-container", {
                display: "flex",
            })
            .to(".next-container", {
                opacity: 1,
                ease: "power1.inOut",
                duration: theme.buttonOpDur,
            });
    }, [additionalInfoValidation, isMobile]);

    const checkPrivacyAction = useCallback(() => {
        gsap.killTweensOf(".next-container");

        const tl = gsap.timeline();

        tl.to(".next-container", {
            opacity: 0,
            ease: "power1.inOut",
            duration: theme.buttonOpDur,
        })
            .addLabel("nextDisappear")
            .set(
                ".next-container",
                {
                    display: "none",
                },
                "nextDisappear+=.3"
            );

        const button = document.querySelector("button[data-text=check]");
        const after = button.querySelector(".after");
        const before = button.querySelector(".before");

        const tl2 = gsap.timeline();

        tl2.set(button, {
            background: "transparent",
        })
            .set(after, {
                x: "100%",
            })
            .set(before, {
                clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
            });
        button.classList.remove("clicked");
    }, []);

    const checkInformationAction = useCallback(() => {
        const tl = gsap.timeline();
        tl.delay(
            isMobile ? theme.stepButtonDelay.mobile : theme.stepButtonDelay.pc
        )
            .set(".next-container", {
                display: "flex",
            })
            .to(".next-container", {
                opacity: 1,
                ease: "power1.inOut",
                duration: theme.buttonOpDur,
            });
    }, [isMobile]);
    const sendAction = useCallback(() => {
        const tl = gsap.timeline();

        tl.to([".home-container", ".next-container"], {
            opacity: 0,
            ease: "power1.inOut",
            duration: theme.buttonOpDur,
        })
            .set(
                [".home-container", ".next-container"],
                {
                    display: "none",
                },
                "+=.3"
            )
            .set(".step-container", {
                justifyContent: "space-between",
            })
            .set([".back", ".back ~ .border-clone"], {
                display: "block",
            })
            .to([".back", ".back ~ .border-clone"], {
                opacity: 1,
                ease: "power1.inOut",
                duration: theme.buttonOpDur,
            });

        const button = document.querySelector("button[data-text=send]");
        const after = button.querySelector(".after");
        const before = button.querySelector(".before");

        const tl2 = gsap.timeline();

        tl2.set(button, {
            background: "transparent",
        })
            .set(after, {
                x: "100%",
            })
            .set(before, {
                clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
            });
        button.classList.remove("clicked");
    }, []);

    const nextActiveCondition = useMemo(
        () => [
            [
                // main
                () => {
                    hideChoise();
                    mainAction();
                },
                // 1
                () => {
                    requestTypeAction();
                },
                // Project
                // 2
                () => {
                    requesterAction();
                },
                // 3
                () => {
                    projectNameValidation();
                },
                // 4
                () => {
                    brandUIRangeValidation();
                },
                // 5
                () => {
                    hideChoise();
                    projectRangeValidation();
                },
                // 6
                () => {
                    showChoise();
                    dueValidation();
                },
                // 7
                () => {
                    hideChoise();
                    priceValidation();
                },
                // 8
                () => {
                    additionalInfoAction();
                },
                // Check Privacy
                () => {
                    checkPrivacyAction();
                },
                // Check Information
                () => {
                    checkInformationAction();
                },
                // Send
                () => {
                    sendAction();
                },
                // Thank you
                () => {},
            ],
            [
                // main
                () => {
                    mainAction();
                },
                // 1
                () => {
                    requestTypeAction();
                },
                // 2
                () => {
                    requesterAction();
                },
                // 3
                () => {
                    requestNameValidation();
                },
                // 4
                () => {
                    kindRangeValidation();
                },
                // 5
                () => {
                    additionalInfoAction();
                },
                // Check Privacy
                () => {
                    checkPrivacyAction();
                },
                // Check Information
                () => {
                    checkInformationAction();
                },
                // Send
                () => {
                    sendAction();
                },
                // Thank you
                () => {},
            ],
        ],
        [
            requestNameValidation,
            requesterAction,
            projectNameValidation,
            brandUIRangeValidation,
            projectRangeValidation,
            dueValidation,
            priceValidation,
            kindRangeValidation,
            sendAction,
            checkInformationAction,
            checkPrivacyAction,
            mainAction,
            requestTypeAction,
            additionalInfoAction,
            hideChoise,
            showChoise,
        ]
    );

    return {
        nextActiveCondition,
    };
};

export default useValidation;
