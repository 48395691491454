import React from "react";
import styled from "@emotion/styled";
import MultiSelectChip from "../components/Chip/MultiSelectChip";
import { rangeBrandData, rangeUIData } from "../data/formData";
import { duplicateOK, projectRangeData } from "../data/titleData";
import { useRecoilValue } from "recoil";
import { selectors } from "../recoil/atom";

const StyledContainer = styled.div`
    .style-wrap {
        .input-container {
            width: 100%;
        }
        .range-container {
            display: grid;
            align-items: center;
            grid-template-columns: 120px 1fr;

            .chip-container {
                justify-self: flex-end;
            }

            @media screen and (max-width: 1055px) {
                grid-template-columns: 1fr;
                .chip-container {
                    width: 100%;
                }
            }

            .range-title {
                font-weight: 500;

                @media screen and (max-width: 1500px) {
                    font-size: 14px;
                }

                @media screen and (max-width: 1055px) {
                    display: none;
                }
            }
        }
        .divider {
            display: block;
            margin: 30px 0;

            height: 2px;
            width: 100%;
            background: rgba(0, 0, 0, 0.05);

            .current & {
                box-shadow: 0px 2px 2px rgba(255, 255, 255, 0.32);
            }

            @media screen and (max-width: 1055px) {
                margin: 20px 0;
            }
        }
    }
`;

const ProjectRangeView = () => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const { title } = projectRangeData[lang];

    return (
        <>
            <StyledContainer
                className="style-container"
                data-step="4"
                data-line
            >
                <div className="style-wrap">
                    <div className="title">
                        <div className="step">Step. 04</div>
                        <div>{title}</div>
                        <div className="sub-text">{duplicateOK[lang]}</div>
                    </div>
                    <div className="input-container">
                        <div className="range-container">
                            <span className="range-title">Brand Design</span>
                            <MultiSelectChip
                                selects={rangeBrandData}
                                coilKey="requestRangeBrand"
                                vertical
                                startTabIndex={0}
                            />
                        </div>
                        <span className="divider"></span>
                        <div className="range-container">
                            <span className="range-title">UX/UI Design</span>
                            <MultiSelectChip
                                selects={rangeUIData}
                                coilKey="requestRangeUI"
                                vertical
                                startTabIndex={4}
                            />
                        </div>
                    </div>
                </div>
            </StyledContainer>
        </>
    );
};

export default React.memo(ProjectRangeView);
