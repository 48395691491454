import styled from "@emotion/styled";
import gsap from "gsap";
import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { alertText } from "../../data/titleData";
import { selectors } from "../../recoil/atom";
import { theme } from "../../theme";

const StyledContainer = styled.div`
    position: absolute;
    left: 50%;
    top: -20px;

    max-width: 500px;

    transform: translate(-50%, -100%);

    padding: 20px 0 20px 40px;

    background: #000;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.15);
    border-radius: 16px;

    @media screen and (max-width: 1055px) {
        padding: 0;
    }

    @media screen and (max-width: 768px) {
        max-width: unset;
        width: calc(
            100% -
                ${theme["1055"].paddingHorizontal.value * 2 +
                theme["1055"].paddingHorizontal.unit}
        );
    }

    .dark-mode & {
        background: #fff;
        box-shadow: 10px 10px 10px rgba(255, 255, 255, 0.15);
    }

    z-index: 502;

    .alert-wrap {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 1055px) {
            min-height: 77px;
        }

        .alert-message-wrapper {
            padding-right: 30px;
            display: flex;
            align-items: center;

            @media screen and (max-width: 1055px) {
                padding: 15px 30px 15px 20px;
            }
        }

        .alert-message {
            font-weight: 400;
            font-size: 14px;
            line-height: 1.4;
            color: #fff;

            word-break: keep-all;

            @media screen and (max-width: 1055px) {
                font-size: 12px;
                max-width: 400px;
            }

            .dark-mode & {
                color: #000;
            }
        }

        .alert-button {
            padding: 0 30px;
            background: transparent;
            outline: none;
            border: none;
            border-left: 1px solid rgba(255, 255, 255, 0.2);

            color: #fff;

            font-weight: 700;
            font-size: 14px;
            line-height: 1.5;
            white-space: nowrap;

            cursor: pointer;

            @media screen and (max-width: 1055px) {
                font-size: 12px;
                padding: 0 20px;
            }

            .dark-mode & {
                border-left: 1px solid rgba(0, 0, 0, 0.2);
                color: #000;
            }
        }
    }
`;

// 터치 방지 dim
const Dim = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 501;
    display: none;

    background: rgba(0, 0, 0, 0);
`;

export let isClosing = false;
export let isShowing = false;

export const setShowing = (show) => {
    isShowing = show;
};

export const closeAlert = (isMobile) => {
    if (!isClosing && isShowing) {
        isClosing = true;
        gsap.to(".alert-container", {
            y: "-100%",
            top: -20,
            duration: isMobile ? 0.4 : 0.5,
            ease: "power2.in",
            onComplete() {
                isClosing = false;
                isShowing = false;
            },
        });

        gsap.set(".alert-dim", {
            display: "none",
        });
    }
};

const Alert = () => {
    const isMobile = useRecoilValue(selectors["getIsMobileWidth"]);
    const lang = useRecoilValue(selectors["getLanguage"]);
    const alertButtonText = alertText[lang];

    useEffect(() => {
        const alertBtn = document.querySelector(".alert-button");
        alertBtn.addEventListener("click", closeAlert.bind(null, isMobile));

        return () => {
            alertBtn.removeEventListener("click", closeAlert);
        };
    }, [isMobile]);

    return (
        <>
            <StyledContainer className="alert-container">
                <div className="alert-wrap">
                    <div className="alert-message-wrapper">
                        <div className="alert-message"></div>
                    </div>
                    <button className="alert-button">{alertButtonText}</button>
                </div>
            </StyledContainer>
            <Dim className="alert-dim" />
        </>
    );
};

export default React.memo(Alert);
