import styled from "@emotion/styled";
import classNames from "classnames";
import gsap from "gsap";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { selectors } from "../../recoil/atom";

const transitionInDurPc = 0.3;
const transitionInDurMobile = 0.3;

const StyledContainer = styled.div`
    .button-container {
        transform-origin: calc(100% - 13vw) center;
        transform: translate(13vw, 0);

        border-radius: 13vw;

        @media screen and (max-width: 1055px) {
            transform: translate(0, 0);
            transform-origin: calc(100% - 250px) center;
        }
    }

    .border-clone {
        position: absolute;
        top: 0;
        z-index: -1;

        background: transparent;

        border-radius: 13vw;
        border: 0.7vw solid #000;

        padding: 0 6.5vw;
        width: 60vw;
        height: 19.6vw;
        transition: color 0.5s cubic-bezier(0.45, 0, 0.55, 1),
            border-color 0.5s cubic-bezier(0.45, 0, 0.55, 1);

        @media screen and (max-width: 1055px) {
            height: 140px;
            border-radius: 70px;
            width: calc(100% + 70px);

            border-width: 6px;
            padding: 0 70px;
        }

        .dark-mode & {
            color: #fff;
            border-color: #fff;
        }
    }
    button {
        font-size: 5.2vw;
        text-align: left;
        font-weight: 700;
        text-transform: uppercase;

        user-select: none;

        background: transparent;
        transform: translateZ(0);

        cursor: pointer;

        color: #000;
        border-radius: 13vw;
        border: 0.5vw solid #000;

        padding: 0 6.5vw;
        width: 60vw;
        height: 19.6vw;

        position: relative;
        overflow: hidden;
        line-height: 0;
        transition: color 0.5s cubic-bezier(0.45, 0, 0.55, 1),
            border-color 0.5s cubic-bezier(0.45, 0, 0.55, 1);

        @media screen and (max-width: 1055px) {
            height: 140px;
            border-radius: 70px;
            width: calc(100% + 70px);

            font-size: 36px;

            border-width: 5px;
            padding: 0 70px;
        }

        .dark-mode & {
            color: #fff;
            border-color: #fff;
        }

        .text {
            pointer-events: none;
            user-select: none;

            .text-line {
                position: relative;
                display: inline-block;
            }

            ${"" /* Safari vertical align */}
            .need-vertical-align & {
                position: relative;
                top: -0.05em;
            }
            ${"" /* End Safari vertical align */}
        }

        & .before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            user-select: none;

            color: #fff;

            font-size: inherit;
            text-align: left;
            font-weight: 700;

            text-transform: uppercase;

            padding: inherit;
            box-sizing: border-box;

            transition: color 0.8s;

            pointer-events: none;

            .dark-mode & {
                color: #000;
            }

            display: flex;

            align-items: center;

            clip-path: polygon(100% 0%, 200% 0%, 200% 100%, 100% 100%);
            will-change: clip-path;

            .ing {
                display: inline-block;
                margin-left: 0.2em;
                transform: scale(0.8);

                .ing-line {
                    display: block;
                    text-align: left;
                    position: relative;
                    overflow: hidden;
                }
                .ing-char {
                    position: relative;
                    display: inline-block;

                    transform: translate(0, 100%);

                    will-change: transform, opacity;

                    @media screen and (max-width: 1055px) {
                        font-size: 1.8em;
                    }
                }
            }

            .before-text {
                display: inline-block;
                position: relative;

                .before-char {
                    display: inline-block;
                    position: relative;
                }
            }

            ${"" /* Safari vertical align */}
            .need-vertical-align & {
                .before-text {
                    top: -0.05em;

                    @media screen and (max-width: 1055px) {
                        & ~ .ing {
                            position: relative;
                            top: -0.05em;
                        }
                    }
                }
            }
            ${"" /* End Safari vertical align */}
        }

        & .after {
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            transform: translate(100%, 0);

            color: #fff;
            background-color: #000;

            transition: background-color 0.5s cubic-bezier(0.45, 0, 0.55, 1),
                border-color 0.5s cubic-bezier(0.45, 0, 0.55, 1);
            will-change: transform;
            .dark-mode & {
                border-color: #fff;
                color: #000;
                background-color: #fff;
            }
        }

        &:active {
            &,
            & ~ .border-clone {
                border-color: #222;
                .dark-mode & {
                    border-color: #eee;
                }
            }
        }
        &:active .after {
            background: #222;
            border-color: #222;
            .dark-mode & {
                background: #eee;
                border-color: #eee;
            }
        }

        &.disabled {
            &,
            & ~ .border-clone {
                border-color: #ccc;
                color: #ccc;

                cursor: initial;
                transition: color 0.5s cubic-bezier(0.33, 1, 0.68, 1),
                    background 0s 0s,
                    border-color 0.5s cubic-bezier(0.33, 1, 0.68, 1);

                .dark-mode & {
                    color: #333;
                    border-color: #333;
                }
            }

            & .after {
                background: #ccc;
                transition: background-color 0.5s cubic-bezier(0.45, 0, 0.55, 1),
                    border-color 0.5s cubic-bezier(0.45, 0, 0.55, 1);
                .dark-mode & {
                    background: #333;
                }
            }

            & .before {
                color: #f0f1f4;

                .dark-mode & {
                    color: #000;
                }
            }

            &.click-occured {
                &,
                & ~ .border-clone {
                    border-color: #000;
                    color: #000;

                    transition: color 0.8s cubic-bezier(0.45, 0, 0.55, 1),
                        border-color 0.8s cubic-bezier(0.45, 0, 0.55, 1) !important;

                    .dark-mode & {
                        color: #fff;
                        border-color: #fff;
                    }
                }

                & .after {
                    background: #000;
                    transition: background-color 0.8s
                            cubic-bezier(0.45, 0, 0.55, 1),
                        border-color 0.8s cubic-bezier(0.45, 0, 0.55, 1);
                    .dark-mode & {
                        background: #fff;
                    }
                }

                & .before {
                    color: #fff;

                    .dark-mode & {
                        color: #000;
                    }
                }
            }
        }
    }

    .dark-mode &.loading {
        .border-clone {
            border-color: #ddd !important;
            transition: border-color 0.8s cubic-bezier(0.45, 0, 0.55, 1);
        }

        button {
            color: #aaa !important;
            border-color: #ddd !important;
            transition: color 0.8s cubic-bezier(0.45, 0, 0.55, 1),
                border-color 0.8s cubic-bezier(0.45, 0, 0.55, 1);
            & .after {
                background: #ddd !important;
                border-color: #ddd !important;
                transition: background-color 0.8s cubic-bezier(0.45, 0, 0.55, 1),
                    border-color 0.8s cubic-bezier(0.45, 0, 0.55, 1);
            }

            & .before {
                color: #aaa !important;
            }
        }
    }
`;

export const hidePoint = (isMobile, points, cbObj) => {
    const tl = gsap.timeline();
    if (isMobile) {
        tl.addLabel("point")
            .to(
                points,
                {
                    autoAlpha: 0,
                    duration: 0.4,
                    ease: "power1.inOut",
                    stagger: -0.07,
                },
                "point"
            )
            .to(
                points,
                {
                    y: "100%",
                    duration: 0.25,
                    ease: "power2.in",
                    stagger: -0.07,
                    onComplete() {
                        const { callback, params } = cbObj;
                        callback(...params);
                    },
                },
                "point"
            );
    } else {
        tl.addLabel("point")
            .to(
                points,
                {
                    autoAlpha: 0,
                    duration: 0.5,
                    ease: "power1.inOut",
                    stagger: -0.07,
                },
                "point"
            )
            .to(
                points,
                {
                    y: "100%",
                    duration: 0.3,
                    ease: "power2.in",
                    stagger: -0.07,
                    onComplete() {
                        const { callback, params } = cbObj;
                        callback(...params);
                    },
                },
                "point"
            );
    }
};

const CheckButton = ({
    text,
    disabled,
    onClick,
    loading,
    loadingFinish,
    error,
    setStopped,
    setPoints,
}) => {
    const button = useRef(null);
    const after = useRef(null);
    const before = useRef(null);

    const [entered, setEntered] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [canClick, setCanClick] = useState(true);

    const isSlideAnimating = useRecoilValue(selectors["getIsSlideAnimating"]);
    const isMobile = useRecoilValue(selectors["getIsMobileWidth"]);

    const isTouchScreen = "ontouchstart" in document.documentElement;

    const enterEvent = useCallback(
        (force) => {
            if (isTouchScreen && !canClick) {
                return;
            }

            if (button.current.classList.contains("disabled")) {
                return;
            }

            if (button.current.dataset.event) {
                return;
            }

            if (isTouchScreen && !force) {
                return;
            }

            const tl = gsap.timeline();
            gsap.killTweensOf([after.current, before.current, button.current]);

            const inDur = isMobile ? 0.6 : 0.8;

            tl.addLabel("start")
                .to(
                    after.current,
                    {
                        x: "0",
                        duration: inDur,
                        ease: "power2.out",
                    },
                    "start"
                )
                .to(
                    before.current,
                    {
                        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                        duration: inDur,
                        ease: "power2.out",
                    },
                    "start"
                );
        },
        [isMobile, isTouchScreen, canClick]
    );

    const leaveEvent = useCallback(() => {
        if (button.current.classList.contains("disabled")) {
            return;
        }

        if (button.current.dataset.event) {
            return;
        }

        if (!button.current.classList.contains("clicked")) {
            const outDur = isMobile ? 0.5 : 0.6;

            const tl = gsap.timeline();
            gsap.killTweensOf([after.current, before.current, button.current]);
            tl.addLabel("start")
                .to(
                    after.current,
                    {
                        x: "100%",
                        duration: outDur,
                        ease: "power2.in",
                    },
                    "start"
                )
                .to(
                    before.current,
                    {
                        clipPath:
                            "polygon(100% 0%, 200% 0%, 200% 100%, 100% 100%)",
                        duration: outDur,
                        ease: "power2.in",
                    },
                    "start"
                )
                .set(after.current, {
                    x: "100%",
                })
                .set(before.current, {
                    clipPath: "polygon(100% 0%, 200% 0%, 200% 100%, 100% 100%)",
                });
        }
    }, [isMobile]);

    useEffect(() => {
        const clickEvent = function (e) {
            if (e.target.classList.contains("disabled")) {
                return;
            }
            if (canClick) {
                setClicked(true);
                if (isTouchScreen) {
                    enterEvent(true);
                }
                onClick(e, button);
            }
        };

        button.current.addEventListener("click", clickEvent);

        const refValue = button.current;
        return () => {
            refValue.removeEventListener("click", clickEvent);
        };
    }, [
        button,
        isMobile,
        isSlideAnimating,
        enterEvent,
        canClick,
        isTouchScreen,
        onClick,
    ]);

    useEffect(() => {
        if (!isSlideAnimating && !isTouchScreen) {
            if (entered) {
                console.log("effect");
                enterEvent();
            }
        }
    }, [isSlideAnimating]);

    useEffect(() => {
        if (loading) {
            const tl = gsap.timeline();

            const ing = button.current.querySelector(".ing");
            const points = button.current.querySelectorAll(".ing-char");

            let stopCnt = 0;

            const jumpTo = "-30%";
            if (ing) {
                tl.set(ing, {
                    autoAlpha: 0,
                    lineHeight: 1,
                })
                    .set(
                        points,
                        {
                            y: "100%",
                            autoAlpha: 1,
                        },
                        ">"
                    )
                    .set(
                        ing,
                        {
                            autoAlpha: 1,
                        },
                        ">"
                    )
                    .addLabel("animationStart", ">")
                    .addLabel("start", "animationStart")
                    .to(
                        points,
                        {
                            y: "0",
                            ease: "power2.in",
                            duration: 0.4,
                            stagger: {
                                each: 0.1,
                                onComplete() {
                                    const target = this.targets()[0];
                                    const index = target.dataset.index * 1;

                                    const tl = gsap.timeline({
                                        repeat: -1,
                                    });

                                    tl.to(target, {
                                        y: jumpTo,
                                        duration: 0.4,
                                        ease: "power2.out",
                                        onComplete() {
                                            if (
                                                (button.current.classList.contains(
                                                    "loading-finished"
                                                ) ||
                                                    button.current.classList.contains(
                                                        "error-occured"
                                                    )) &&
                                                stopCnt === index
                                            ) {
                                                stopCnt++;
                                                tl.kill();
                                                if (
                                                    stopCnt === 3 &&
                                                    button.current.classList.contains(
                                                        "error-occured"
                                                    )
                                                ) {
                                                    button.current.classList.remove(
                                                        "error-occured"
                                                    );
                                                    setPoints(points);
                                                    return;
                                                } else if (stopCnt === 3) {
                                                    setStopped(true);
                                                }
                                            }
                                        },
                                    }).to(target, {
                                        y: 0,
                                        duration: 0.4,
                                        ease: "power2.in",
                                    });
                                },
                            },
                        },
                        "start"
                    );
            }
        }
    }, [loading, setStopped, isMobile, setPoints]);

    useEffect(() => {
        if (clicked) {
            if (!isSlideAnimating) {
                setClicked(false);
            }
        }
    }, [isSlideAnimating]);

    useEffect(() => {
        if (error) {
            button.current.classList.add("error-occured");
        }
    }, [error]);

    useEffect(() => {
        if (loadingFinish) {
            button.current.classList.add("loading-finished");
        }
    }, [loadingFinish]);

    useEffect(() => {
        if (isSlideAnimating) {
            setCanClick(false);
        } else {
            gsap.delayedCall(
                isMobile ? transitionInDurMobile : transitionInDurPc,
                () => {
                    setCanClick(true);
                }
            );
        }
    }, [isSlideAnimating, isMobile]);

    useEffect(() => {
        const btnCont = button.current.parentNode;

        if (isSlideAnimating) {
            gsap.set(btnCont, {
                pointerEvents: "none",
            });
        } else {
            gsap.set(btnCont, {
                pointerEvents: "auto",
            });
        }
    }, [isSlideAnimating]);

    const containerEnter = useCallback((e) => {
        setEntered(true);
    }, []);
    const containerLeave = useCallback((e) => {
        setEntered(false);
    }, []);

    return (
        <StyledContainer
            className={classNames((loading || loadingFinish) && "loading")}
        >
            <div
                className="button-container"
                onMouseEnter={containerEnter}
                onMouseOut={containerLeave}
            >
                <button
                    className={classNames(
                        (disabled || isSlideAnimating) && "disabled",
                        !disabled && "click-occured",
                        clicked && "clicked",
                        "main-button"
                    )}
                    ref={button}
                    data-text={text}
                    onMouseEnter={enterEvent}
                    onMouseLeave={leaveEvent}
                >
                    <span className="text">
                        <div className="text-line">{text}</div>
                    </span>
                    <span className="after" ref={after}></span>
                    <span className="before" ref={before}>
                        <span className="before-text">
                            {text &&
                                text.split("").map((t, i) => (
                                    <div className="before-char" key={i}>
                                        {t}
                                    </div>
                                ))}
                        </span>
                        {text === "send" && (
                            <span className="ing">
                                <div className="ing-line">
                                    <div className="ing-char" data-index="0">
                                        .
                                    </div>
                                    <div className="ing-char" data-index="1">
                                        .
                                    </div>
                                    <div className="ing-char" data-index="2">
                                        .
                                    </div>
                                </div>
                            </span>
                        )}
                    </span>
                </button>
                <div className="border-clone"></div>
            </div>
        </StyledContainer>
    );
};

export default React.memo(CheckButton);
