import styled from "@emotion/styled";
import classNames from "classnames";
import React, { useCallback, useRef } from "react";
import { useRecoilValue } from "recoil";
import { selectors } from "../../recoil/atom";

const StyledContainer = styled.div`
    opacity: 0;
    visibility: visible;

    position: absolute;
    left: 0.8em;
    top: 50%;

    transform: translate(-100%, -50%);

    transition: opacity 0.2s cubic-bezier(0.45, 0, 0.55, 1);
    max-height: 90%;

    @media screen and (max-width: 1055px) {
        left: 0;
        top: 50%;

        transform: translate(-50%, -50%);
    }

    &.hide {
        visibility: hidden;
    }

    .after {
        min-width: 1.7em;
        max-height: 1.7em;

        word-break: keep-all;

        background: #ff0000;
        border-radius: 0.6em;

        display: flex;
        justify-content: center;
        align-items: center;

        color: #fff;
        font-weight: 700;
        font-size: 1.4vw;
        font-family: "Varela Round", sans-serif;

        padding: 0.6em;

        .current & {
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.15);
        }

        @media screen and (max-width: 1055px) {
            font-size: 14px;
            min-width: 20px;
            height: 20px;

            border-radius: 5px;

            padding: 5px 10px;
        }
    }

    &.with-text {
        .after {
            padding: 0.4em 0.6em;
        }
    }

    .error-text {
        font-size: 1vw;
        line-height: 20px;
        font-family: "NotoSans", sans-serif;
        font-weight: 400;

        position: relative;

        padding-left: 2em;

        @media screen and (min-width: 1400px) {
            font-size: 14px;
        }

        &::before {
            content: "";
            width: 1.5em;
            height: 100%;

            position: absolute;
            left: 0;
            top: 0;

            background: url(/svg/ico_error.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 1.3em 1.3em;
        }
    }

    .before {
        position: absolute;
        right: 0;
        top: 50%;

        background: #ff0000;

        width: 0.5em;
        height: 0.5em;

        min-width: 5px;
        min-height: 5px;

        transform-origin: center;
        transform: translate(50%, -50%) rotate(45deg);

        .current & {
            box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.15);
        }

        @media screen and (max-width: 1055px) {
            width: 6px;
            height: 6px;
        }
    }

    .error ~ &.warn-container.error-only {
        opacity: 1;
        visibility: visible !important;

        transition-property: opacity;
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.45, 0, 0.55, 1);
    }

    .error-with-text ~ &.warn-container.with-text {
        opacity: 1;
        visibility: visible !important;

        transition-property: opacity;
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0.45, 0, 0.55, 1);
    }
`;

const Warn = ({ errorText }) => {
    const isMobile = useRecoilValue(selectors["getIsMobileWidth"]);
    const warn = useRef(null);

    const handleTransitionEnd = useCallback((e) => {
        if (e.elapsedTime === 0.2) {
            warn.current.classList.add("hide");
        } else if (e.elapsedTime === 0.3) {
            warn.current.classList.remove("hide");
        }
    }, []);

    return (
        <StyledContainer
            className={classNames(
                "warn-container",
                "hide",
                errorText ? "with-text" : "error-only"
            )}
            ref={warn}
            onTransitionEnd={handleTransitionEnd}
        >
            <div className="after">
                {errorText && !isMobile ? (
                    <div className="error-text">{errorText}</div>
                ) : (
                    "!"
                )}
            </div>
            <div className="before"></div>
        </StyledContainer>
    );
};

export default React.memo(Warn);
