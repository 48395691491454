import React from "react";
import styled from "@emotion/styled";
import Chip from "./Chip";

import { useRecoilState, useRecoilValue } from "recoil";
import { atoms, selectors } from "../../../recoil/atom";
import classNames from "classnames";

const ChoiseContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    position: relative;

    &.small-chip {
        flex-wrap: nowrap;
    }

    @media screen and (max-width: 1055px) {
        display: grid;
        grid-template-columns: repeat(2, minmax(50%, 50%));
        grid-auto-rows: 1fr;
    }
`;

const ChoiseChip = ({ choises, big, small, coilKey }) => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const [currentChecked, setCurrentChecked] = useRecoilState(atoms[coilKey]);

    const handleChange = (e) => {
        setCurrentChecked(e.target.value);
    };

    return (
        <ChoiseContainer
            className={classNames("chip-container", small && "small-chip")}
        >
            {Object.keys(choises).map((choise, i) => (
                <Chip
                    key={choises[choise].id}
                    value={choise}
                    id={choises[choise].id}
                    text={choises[choise].text[lang]}
                    handleChange={handleChange}
                    checked={currentChecked === choise}
                    name={coilKey}
                    big={big}
                    small={small}
                    tooltipText={
                        choises[choise].tooltipText &&
                        choises[choise].tooltipText[lang]
                    }
                    data-tabindex={i + 1}
                />
            ))}
        </ChoiseContainer>
    );
};

export default React.memo(ChoiseChip);
