import styled from "@emotion/styled";
import gsap from "gsap";
import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { checkPrivacyData } from "../data/titleData";
import { selectors } from "../recoil/atom";

const mobileClosePaddingTop = 30;
const mobileClosePaddingSide = 20;

const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;

    display: none;
    opacity: 0;

    z-index: 600;

    @media screen and (max-width: 1300px) {
        padding: 0 25px;
    }

    .modal-wrap {
        word-break: keep-all;

        padding: 50px;

        width: 40vw;
        max-width: 780px;

        height: 50vh;
        max-height: 540px;

        border-radius: 34px;

        background: #fff;

        @media screen and (max-width: 1300px) {
            width: 60vw;
        }

        @media screen and (max-width: 1055px) {
            padding: 30px 25px;
            border-radius: 17px;
            height: 70vh;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
        }

        .modal-title-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-bottom: 20px;

            @media screen and (max-width: 1055px) {
                position: relative;
                margin-top: -${mobileClosePaddingTop}px;
                left: -${mobileClosePaddingSide}px;
                width: calc(100% + ${mobileClosePaddingSide * 2}px);

                padding: ${mobileClosePaddingTop}px ${mobileClosePaddingSide}px
                    0;
            }

            .modal-title {
                font-size: 26px;
                line-height: 1.5;
                letter-spacing: -0.04em;

                font-weight: 700;

                @media screen and (max-width: 768px) {
                    font-size: 18px;
                }
            }

            .modal-close-wrap {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                cursor: pointer;

                outline: none;
                border: none;
                background: none;

                padding: 10px;

                @media screen and (max-width: 1055px) {
                    position: absolute;

                    padding-top: calc(${mobileClosePaddingTop}px + 10px);
                    padding-right: calc(${mobileClosePaddingSide}px + 10px);

                    top: -${mobileClosePaddingTop}px;
                    right: -${mobileClosePaddingSide}px;

                    transform: translate(
                        -${mobileClosePaddingSide}px,
                        ${mobileClosePaddingTop}px
                    );
                }

                .modal-close {
                    width: 19px;
                    height: 19px;

                    background: url(svg/close-black.svg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;

                    pointer-events: none;
                }
            }
        }

        .modal-content--container {
            padding-right: 60px;
            padding-top: 40px;
            width: 100%;
            height: calc(100% - 70px);

            overflow: auto;

            &::-webkit-scrollbar {
                width: 10px;
                background: transparent;

                @media screen and (max-width: 1055px) {
                    width: 6px;
                }
            }

            &::-webkit-scrollbar-thumb {
                background: #ccc;
                border-radius: 6px;
                margin-right: 60px;
            }

            &::-webkit-scrollbar-track {
                margin-right: 60px;
            }

            @media screen and (max-width: 768px) {
                font-size: 12px;
                padding: 20px 20px 0 0;
            }

            .modal-body-wrap {
                .modal-body-item {
                    margin-bottom: 50px;

                    @media screen and (max-width: 1055px) {
                        margin-bottom: 20px;
                    }

                    &:nth-last-of-type(1) {
                        margin: 0;
                    }

                    .modal-title-sub {
                        margin-bottom: 20px;
                        font-weight: 700;

                        @media screen and (max-width: 1055px) {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
`;

const ModalView = () => {
    const isMobile = useRecoilValue(selectors["getIsMobileWidth"]);
    const lang = useRecoilValue(selectors["getLanguage"]);
    const { modalTitle, modalContent } = checkPrivacyData[lang];

    useEffect(() => {
        const modal = document.querySelector(".modal-container");

        const clickHandler = (e) => {
            if (
                e.target.classList.contains("modal-container") ||
                e.target.classList.contains("modal-close-wrap")
            ) {
                const tl = gsap.timeline();

                tl.addLabel("start")
                    .to(
                        ".modal-wrap",
                        {
                            opacity: 0,
                            duration: isMobile ? 0.3 : 0.4,
                            ease: "power1.inOut",
                        },
                        "start"
                    )
                    .to(
                        ".modal-container",
                        {
                            opacity: 0,
                            duration: isMobile ? 0.3 : 0.4,
                            ease: "power1.inOut",
                            onComplete() {
                                const scrollContainer = modal.querySelector(
                                    ".modal-content--container"
                                );
                                scrollContainer.scrollTo(0, 0);
                            },
                        },
                        isMobile ? ">-.2" : ">-.3"
                    )
                    .set(".modal-container", {
                        display: "none",
                    });
            }
        };

        modal.addEventListener("click", clickHandler);

        return () => {
            modal.removeEventListener("click", clickHandler);
        };
    }, [isMobile]);

    return (
        <ModalContainer className="modal-container">
            <div className="modal-wrap">
                <div className="modal-title-wrap">
                    <div className="modal-title">{modalTitle}</div>
                    <button className="modal-close-wrap">
                        <div className="modal-close"></div>
                    </button>
                </div>
                <div className="modal-content--container">
                    <div className="modal-content-wrap">
                        <div className="modal-body-wrap">{modalContent}</div>
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
};

export default React.memo(ModalView);
