import React from "react";
import styled from "@emotion/styled";
import { requestPriceData } from "../data/formData";
import ChoiseChip from "../components/Chip/ChoiseChip";
import { projectPriceData } from "../data/titleData";
import { useRecoilValue } from "recoil";
import { selectors } from "../recoil/atom";

const StyledContainer = styled.div`
    .style-wrap {
        .input-container {
            width: 100%;
        }
    }
`;

const ProjectPriceView = () => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const { title } = projectPriceData[lang];
    return (
        <>
            <StyledContainer
                className="style-container"
                data-step="7"
                data-line
            >
                <div className="style-wrap">
                    <div className="title">
                        <div className="step">Step. 07</div>
                        <div>{title}</div>
                    </div>
                    <div className="input-container">
                        <ChoiseChip
                            coilKey="requestPrice"
                            choises={requestPriceData}
                        />
                    </div>
                </div>
            </StyledContainer>
        </>
    );
};

export default React.memo(ProjectPriceView);
