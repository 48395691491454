import ProjectNameView from "./ProjectNameView";
import ProjectPriceView from "./ProjectPriceView";
import ProjectRangeView from "./ProjectRangeView";
import ProjectRequestView from "./ProjectRequestView";
import RequestKindView from "./RequestKindView";
import RequestNameView from "./RquestNameView";
import ProjectDueView from "./ProjectDueView";

const ProjectViews = () => {
    return (
        <>
            <ProjectNameView />
            <ProjectRangeView />
            <ProjectRequestView />
            <ProjectDueView />
            <ProjectPriceView />
        </>
    );
};

const RequestViews = () => {
    return (
        <>
            <RequestNameView />
            <RequestKindView />
        </>
    );
};

const Views = [ProjectViews, RequestViews];

export default Views;
