import React from "react";
import styled from "@emotion/styled";
import { rangeKindData } from "../data/formData";
import ChoiseChip from "../components/Chip/ChoiseChip";
import { useRecoilValue } from "recoil";
import { selectors } from "../recoil/atom";
import NormalInput from "../components/Input/NormalInput";
import classNames from "classnames";
import { requestKindData } from "../data/titleData";

const StyledContainer = styled.div`
    .style-wrap {
        .input-container {
            width: 100%;
            position: relative;

            .chip-container {
                justify-content: flex-start;
            }

            .etc-detail {
                position: absolute;
                width: 100%;
                padding: 0 calc((100% - (13.4vw * 3)) / 2 + 0.5vw);
                bottom: 0;

                transform: translate(0, 100%);

                opacity: 0;
                transition: opacity 0.3s;

                &.show {
                    opacity: 1;
                }

                @media screen and (max-width: 1055px) {
                    padding: 0;
                }
            }
        }
    }
`;

const RequestKindView = () => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const kindRange = useRecoilValue(selectors["getRequestRangeKind"]);
    const { title, etcRequestPlaceholder } = requestKindData[lang];

    return (
        <>
            <StyledContainer
                className="style-container"
                data-step="4"
                data-line
            >
                <div className="style-wrap">
                    <div className="title">
                        <div className="step">Step. 04</div>
                        <div>{title}</div>
                    </div>
                    <div className="input-container">
                        <ChoiseChip
                            choises={rangeKindData}
                            coilKey="requestRangeKind"
                        />

                        <div
                            className={classNames(
                                "etc-detail",
                                kindRange === "etc" && "show"
                            )}
                        >
                            <NormalInput
                                placeholder={etcRequestPlaceholder}
                                type="text"
                                coilKey="kindEtcDetail"
                            />
                        </div>
                    </div>
                </div>
            </StyledContainer>
        </>
    );
};

export default React.memo(RequestKindView);
