import gsap from "gsap";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import {
    closeAlert,
    isClosing,
    isShowing,
    setShowing,
} from "../components/Alert";
import { selectors } from "../recoil/atom";

const useAlert = () => {
    const isMobile = useRecoilValue(selectors["getIsMobileWidth"]);

    const listenEvent = useCallback((cb) => {
        const alertBtn = document.querySelector(".alert-button");

        alertBtn.addEventListener(
            "click",
            () => {
                if (cb) {
                    cb();
                }
            },
            { once: true }
        );
    }, []);

    const toastAlert = useCallback(
        (message, cb) => {
            if (!isClosing && !isShowing) {
                setShowing(true);
                const tl = gsap.timeline();

                tl.set(".alert-dim", {
                    display: "block",
                    zIndex: cb ? 501 : -1,
                })
                    .set(".alert-message", {
                        innerHTML: message,
                    })
                    .addLabel("start")
                    .to(
                        ".alert-container",
                        {
                            // top: header 윗 패딩 + header 높이의 절반
                            // => 팝업 수직 가운데 정렬
                            y: "0",
                            top: isMobile
                                ? `${30}px`
                                : `${(window.innerHeight / 100) * 7.4 - 25}px`,
                            duration: isMobile ? 0.4 : 0.5,
                            ease: "power2.out",
                        },
                        "start"
                    )
                    .call(listenEvent, [cb]);

                if (!cb) {
                    gsap.delayedCall(3, closeAlert);
                }
            }
        },
        [listenEvent, isMobile]
    );

    return { toastAlert };
};

export default useAlert;
