import React from "react";
import styled from "@emotion/styled";
import Input from "../components/Input";
import { requestNameData } from "../data/titleData";
import { useRecoilValue } from "recoil";
import { selectors } from "../recoil/atom";

const StyledContainer = styled.div`
    .style-wrap {
        padding-right: 216px;
        width: calc(100% + 216px);

        @media screen and (max-width: 1055px) {
            padding-right: 25px;
            width: 100%;
        }

        .input-container {
            @media screen and (max-width: 1055px) {
                width: calc(100% + 50px);

                align-self: flex-start;
            }

            .warn-container {
                top: 50%;
                transform: translate(-100%, -50%);

                @media screen and (max-width: 1055px) {
                    left: 23px;

                    .after {
                        min-width: 35px;
                        height: 35px;

                        border-radius: 8px;

                        font-size: 22px;
                    }
                }
            }
        }
    }
`;
const RequestNameView = () => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const { title, namePlaceholder } = requestNameData[lang];

    return (
        <>
            <StyledContainer className="style-container" data-step="3">
                <div className="style-wrap">
                    <div className="title">
                        <div className="step">Step. 03</div>
                        <div>{title}</div>
                    </div>
                    <div className="input-container">
                        <Input
                            placeholder={namePlaceholder}
                            coilKey="requestProject"
                            name="requestName"
                            type="text"
                            data-tabindex="1"
                            big
                        />
                    </div>
                </div>
            </StyledContainer>
        </>
    );
};

export default React.memo(RequestNameView);
