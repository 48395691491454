import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { formatMonth, selectors } from "../recoil/atom";
import Textarea from "../components/Input/Textarea";
import File from "../components/Input/File";
import { additionalInfoData } from "../data/titleData";
import useAlert from "../hooks/useAlert";

const StyledContainer = styled.div`
    .style-wrap {
        .file-container {
            margin-top: 20px;

            @media screen and (max-width: 1055px) {
                margin-top: 10px;
            }
        }

        .input-container {
            width: 100%;
        }
    }
`;
const AdditionalInfoView = () => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const requestType = useRecoilValue(selectors["getRequestType"]);
    const step = requestType !== "etc" ? 8 : 5;

    const { toastAlert } = useAlert();

    const { title, choiseTxt, filePlaceholder, textareaPlaceholder } =
        additionalInfoData[lang];

    return (
        <>
            <StyledContainer className="style-container" data-step={step}>
                <div className="style-wrap">
                    <div className="title">
                        <div className="step">Step. {formatMonth(step)}</div>
                        <div>{title}</div>
                        <div className="sub-text">{choiseTxt}</div>
                    </div>
                    <div className="input-container">
                        <Textarea
                            placeholder={textareaPlaceholder}
                            coilKey="additionalInfo"
                            name="text"
                        />
                        <File
                            toastAlert={toastAlert}
                            coilKey="additionalInfo"
                            label={filePlaceholder}
                            name="file"
                            id="additionalFile"
                        />
                    </div>
                </div>
            </StyledContainer>
        </>
    );
};

export default React.memo(AdditionalInfoView);
