import React, { useCallback, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import Header from "../Header";
import StepContainer from "../StepContainer";
import { useRecoilState, useRecoilValue } from "recoil";
import { atoms, selectors } from "../../recoil/atom";
import Views from "../../views";
import gsap from "gsap";
import ModalView from "../../views/ModalView";
import Progress from "../Progress";
import Alert from "../Alert";
import LineView from "../../views/LineView";
import { theme } from "../../theme";
import AnimationWrapper from "../AnimationWrapper";
import Loading from "../Loading";
import useReset from "../../hooks/useReset";
import useTab from "../../hooks/useTab";
import { adjustScheduleText } from "../../data/titleData";

import MainView from "../../views/MainView";
import RequestTypeView from "../../views/RequestTypeView";
import RequesterView from "../../views/RequesterView";

import AdditionalInfoView from "../../views/AdditionalInfoView";
import CheckPrivacyView from "../../views/CheckPrivacyView";
import CheckInformationView from "../../views/CheckInformationView";
import SendFormView from "../../views/SendFormView";
import FinalView from "../../views/FinalView";

const AppContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;

    .no-touch-screen & {
        min-height: 650px;
    }

    overflow: hidden;

    .style-container {
        width: 100vw;
        height: 100%;
        flex-shrink: 0;

        position: absolute;
        z-index: -1;

        display: none;

        &.active {
            z-index: 1 !important;
        }

        @media screen and (max-width: 1055px) {
            padding: 0;
        }
    }

    .style-wrap {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;

        justify-content: center;

        padding: 0
            ${theme["normal"].paddingHorizontal.value * 2 +
            theme["normal"].paddingHorizontal.unit};

        @media screen and (max-width: 1300px) {
            padding: 0 4.2vw;
        }

        @media screen and (max-width: 1055px) {
            flex-direction: column;
            justify-content: flex-start;

            padding: 25px 25px 20px;
        }

        @media screen and (max-width: 375px) {
            padding-top: 15px;
        }

        .title {
            position: relative;
            font-size: 3.4vw;
            font-weight: 700;
            line-height: 1.25;

            padding-right: 0.45em;
            white-space: nowrap;

            flex: 1;
            opacity: 0;
            visibility: hidden;

            will-change: transform, opacity;

            @media screen and (max-width: 1500px) {
                flex: 2;
            }
            @media screen and (max-width: 1300px) {
                flex: 1;
            }

            @media screen and (max-width: 1055px) {
                align-self: flex-start;
                font-size: 22px;
                line-height: 1.5;
                flex: 0 0 auto;

                .no-touch-screen & {
                    min-height: 21vh;
                    height: max-content;
                }

                .touch-screen & {
                    height: 21vh;
                }

                margin-bottom: 15px;
            }

            .dark-mode & {
                color: #fff;
            }

            .step {
                font-size: 18px;
                margin-bottom: 20px;

                @media screen and (max-width: 1055px) {
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }

            .sub-text {
                color: #999;
                font-size: 16px;
                line-height: 1.5;
                margin-top: 20px;
                font-weight: 400;

                @media screen and (max-width: 1055px) {
                    font-size: 13px;
                    line-height: 20px;
                    margin-top: 15px;
                }
            }
        }

        .input-container {
            flex: 1;
            opacity: 0;
            visibility: hidden;

            will-change: transform, opacity;

            @media screen and (max-width: 1500px) {
                flex: 3;
            }
            @media screen and (max-width: 1300px) {
                flex: 1;
            }

            @media screen and (max-width: 1055px) {
                flex: 0;
            }
        }
    }
`;

const ContentContainer = styled.div`
    position: absolute;
    width: 100%;
    height: calc(
        100% -
            ${theme["normal"].paddingVertical.value * 2 +
            theme["normal"].paddingVertical.unit} - 40px - 96px - 25px
    );
    margin-top: calc(
        ${theme["normal"].paddingVertical.value +
            theme["normal"].paddingVertical.unit} + 40px + 25px
    );
    z-index: 501;

    @media screen and (max-width: 1055px) {
        height: calc(
            100% -
                ${theme["1055"].paddingVertical.value * 2 +
                theme["1055"].paddingVertical.unit} - 77px - 55px + 25px
        );
        margin-top: calc(
            ${theme["1055"].paddingVertical.value +
                theme["1055"].paddingVertical.unit} + 77px - 25px
        );
    }
`;

const ContentWrapper = styled.div`
    height: 100%;
    position: relative;

    display: flex;
    flex-wrap: nowrap;
`;

const App = () => {
    /**
     * * 변수 정보
     */

    // 상태 초기화 hook 관련 변수
    const { resetAll: dataReset } = useReset();

    // Recoil에서 모바일 넓이 체크 및 설정
    const [isMobile, setIsMobile] = useRecoilState(atoms["mobileWidth"]);

    // Recoil에서 언어 체크 및 설정
    const [lang, setLang] = useRecoilState(atoms["language"]);

    // 의뢰 종류에 맞는 슬라이드 렌더링
    const requestType = useRecoilValue(selectors["getRequestType"]);
    const requestTypeIndex = requestType !== "etc" ? 0 : 1;
    const ContentView = requestType !== "etc" ? Views[0] : Views[1];

    // 현재 보여지고 있는 슬라이드 인덱스
    const currentActive = useRecoilValue(selectors["getCurrentActive"]);

    // Tab hook 관련 변수
    const { tabControl, setTab, addTabFrom } = useTab(currentActive);
    useEffect(() => {
        setTab(0);
    }, [setTab, currentActive]);

    useEffect(() => {
        const swiper = document.querySelector(".swiper-wrapper");

        window.onkeydown = function (e) {
            if (e.keyCode === 9 || e.keyCode === 13) {
                if (e.keyCode === 13 && e.target.tagName === "TEXTAREA") {
                    return;
                }
                // Tab
                e.preventDefault();

                const currentActive = swiper.dataset.active * 1;
                const currentType = swiper.dataset.type * 1;

                if (!e.shiftKey || isMobile) {
                    addTabFrom(1);
                } else if (e.shiftKey) {
                    addTabFrom(-1);
                }

                tabControl[currentType][currentActive]();
            }
        };
    }, [tabControl, addTabFrom, isMobile]);
    // 모바일 이슈들
    const initialHeight = useRef(window.innerHeight);
    const isAndroid = /Android/.test(navigator.userAgent);
    const needVerticalAlign = /^((?!chrome|android).)*(safari|firefox)/i.test(
        navigator.userAgent
    );

    useEffect(() => {
        const scrollHandler = () => {
            gsap.set([".view-container", ".header-container"], {
                y: `-${window.scrollY}px`,
            });
        };

        if (isAndroid) {
            // Android 키보드 position fixed 스크롤 이슈
            gsap.set([".view-container", ".header-container"], {
                y: `-${0}px`,
            });
            window.addEventListener("scroll", scrollHandler);
        }

        const resizeHandler = () => {
            // Android 키보드 밀림 현상 이슈
            document.documentElement.style.setProperty("overflow", "auto");
            const metaViewport = document.querySelector("meta[name=viewport]");
            metaViewport.setAttribute(
                "content",
                "height=" +
                    initialHeight.current +
                    "px, width=device-width, initial-scale=1.0"
            );
        };
        if (isAndroid) {
            window.visualViewport.addEventListener("resize", resizeHandler);
        }
        if (isAndroid) {
            document.querySelector(".view-container").style.position = "fixed";
        }

        if (needVerticalAlign) {
            document.body.classList.add("need-vertical-align");
        } else {
            document.body.classList.remove("need-vertical-align");
        }

        return () => {
            window.removeEventListener("scroll", scrollHandler);
            window.removeEventListener("resize", resizeHandler);
        };
    }, [isAndroid, needVerticalAlign]);

    useEffect(() => {
        if (requestType !== -1) {
            dataReset(requestType);
        }
    }, [requestType, dataReset]);

    useEffect(() => {
        if ("ontouchstart" in document.documentElement) {
            document.body.classList.add("touch-screen");
            document.body.classList.remove("no-touch-screen");
        } else {
            document.body.classList.remove("touch-screen");
            document.body.classList.add("no-touch-screen");
        }
    }, [isMobile]);

    useEffect(() => {
        // 모바일 화면인지 체크
        let prevSize = window.innerWidth;

        const resizeHandler = function () {
            if (this.innerWidth <= 1055 && prevSize > 1055) {
                // Mobile width
                setIsMobile(true);
            } else if (this.innerWidth > 1055 && prevSize <= 1055) {
                // PC width
                setIsMobile(false);
            }
            prevSize = this.innerWidth;
        };

        window.addEventListener("resize", resizeHandler);

        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, [setIsMobile]);

    // 언어 변경
    const handleLanguage = (e) => {
        if (e.target.classList.contains("lang-kr")) {
            setLang("kr");
        }
        if (e.target.classList.contains("lang-en")) {
            setLang("en");
        }
    };

    useEffect(() => {
        document.documentElement.lang = lang;
    }, [lang]);

    const decideRender = useCallback(
        (index) => {
            if (requestTypeIndex === 1) {
                // 기타 의뢰
                if (currentActive >= index) {
                    return true;
                }
            } else {
                // 프로젝트 의뢰
                if (currentActive >= index + 3) {
                    return true;
                }
            }

            return false;
        },
        [requestTypeIndex, currentActive]
    );

    return (
        <AppContainer className="app-container">
            {/* 로딩 딤 */}
            <Loading />
            {/* 헤더 */}
            <Header handleLanguage={handleLanguage} />
            {/* 메세지 창 */}
            <Alert />
            {/* 컨텐츠 */}
            <ContentContainer className="view-container">
                <LineView />
                <ContentWrapper
                    className="swiper-wrapper"
                    data-active="0"
                    data-type={requestTypeIndex}
                >
                    <MainView />
                    <RequestTypeView />
                    <RequesterView />

                    <ContentView />
                    <AdditionalInfoView />
                    <CheckPrivacyView />
                    <AnimationWrapper render={decideRender(6)}>
                        <CheckInformationView />
                    </AnimationWrapper>
                    <AnimationWrapper render={decideRender(7)}>
                        <SendFormView />
                    </AnimationWrapper>
                    <AnimationWrapper render={decideRender(8)}>
                        <FinalView />
                    </AnimationWrapper>
                </ContentWrapper>
            </ContentContainer>
            {/* 하단 버튼 */}
            <StepContainer choise={adjustScheduleText[lang]} />
            {/* Progress bar */}
            <Progress />
            {/* Modal */}
            <ModalView />
        </AppContainer>
    );
};

export default App;
