import React from "react";
import UpDownSelector from ".";
import styled from "@emotion/styled";

const DueContainer = styled.div`
    .date-text {
        color: #999;
        font-size: 18px;
        font-weight: 500;

        margin-bottom: 10px;

        @media screen and (max-width: 1055px) {
            font-size: 14px;
        }
    }

    .updowns {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 1055px) {
            justify-content: space-between;
            align-items: center;
        }
    }
`;

const DateSelector = ({ coilKey, text }) => {
    return (
        <DueContainer>
            <div className="date-text">{text}</div>
            <div className="updowns">
                <UpDownSelector coilKey={coilKey} name="year" />
                <UpDownSelector coilKey={coilKey} name="month" />
                <UpDownSelector coilKey={coilKey} name="due" />
            </div>
        </DueContainer>
    );
};

export default React.memo(DateSelector);
