import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import classNames from "classnames";
import { gsap } from "gsap";
import { useRecoilValue } from "recoil";
import { selectors } from "../../recoil/atom";

const mobilePadding = 12;

const transitionInDurPc = 0.3;
const transitionInDurMobile = 0.3;
const transitionInEase = "cubic-bezier(0.45, 0, 0.55, 1)";

const transitionOutDurPc = 0.5;
const transitionOutDurMobile = 0.4;
const transitionOutEase = "cubic-bezier(0.33, 1, 0.68, 1)";

const StyledContainer = styled.div`
    font-size: 40px;
    border-radius: 4.5em;
    position: relative;
    display: flex;

    user-select: none;

    @media screen and (max-width: 1500px) {
        font-size: 36px;
    }

    @media screen and (max-width: 1055px) {
        right: -${mobilePadding}px;
        bottom: -${mobilePadding}px;
    }

    &.back-container {
        @media screen and (max-width: 1055px) {
            right: unset;
            left: -${mobilePadding}px;
        }
    }

    .border-clone {
        position: absolute;
        width: calc(100% - 2px);
        height: calc(100% - 2px);

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        font-size: 40px;
        border-radius: 4.25em;
        border-width: 5px;
        border-style: solid;
        border-color: #000;

        pointer-events: none;

        transition-property: border-color;
        transition-duration: ${transitionOutDurPc}s;
        transition-timing-function: ${transitionOutEase};

        z-index: 1;

        .dark-mode & {
            border-color: #fff;
        }

        @media screen and (max-width: 1055px) {
            transition-duration: ${transitionOutDurMobile}s;

            border-width: 4px;
            font-size: 26px;

            width: calc(100% - 24px);
            height: calc(100% - 24px);

            border-radius: 170px;
        }
    }

    .disabled ~ .border-clone {
        transition-duration: ${transitionInDurPc}s;
        transition-timing-function: ${transitionInEase};
        @media screen and (max-width: 1055px) {
            transition-duration: ${transitionInDurMobile}s;
        }

        border-color: #ccc;

        .dark-mode & {
            border-color: #333;
        }
    }

    button:not(.disabled):active ~ .border-clone {
        border-color: #222;
        .dark-mode & {
            border-color: #eee;
        }
    }

    button {
        position: relative;

        outline: none;
        background: transparent;
        border: none;

        .text {
            display: block;

            border-width: 4px;
            border-style: solid;
            border-color: #000;
            border-radius: 4.25em;
            padding: 0.5em 0.8em;

            pointer-events: none;

            user-select: none;

            position: relative;
            overflow: hidden;
            isolation: isolate;
            transition-property: color, border-color;
            transition-duration: ${transitionOutDurPc}s;
            transition-timing-function: ${transitionOutEase};

            @media screen and (max-width: 1055px) {
                transition-duration: ${transitionOutDurMobile}s;
            }
        }

        ${"" /* Safari text vertical align issue */}
        .need-vertical-align & {
            .inner-text {
                display: inline;
                vertical-align: 0.1em;
            }

            .before-text {
                position: absolute;
                top: 50%;
                transform: translate(0, calc(-50% - 0.06em));
            }
        }

        ${"" /* End Safari text vertical align issue*/}

        @media screen and (max-width: 1055px) {
            padding: ${mobilePadding}px;
        }

        font-size: inherit;
        font-weight: 700;
        line-height: 1.1;

        text-transform: uppercase;

        cursor: pointer;

        &.back {
            display: none;
            opacity: 0;
        }

        .dark-mode & .text {
            border-color: #fff;
            color: #fff;
        }

        @media screen and (max-width: 1055px) {
            .text {
                border-width: 2px;
                font-size: 26px;
                padding: 6px 18px;
                line-height: 35px;
                border-radius: 170px;
            }
        }

        & .before {
            position: absolute;
            top: -3px;
            right: -3px;
            width: calc(100% + 6px);
            height: calc(100% + 6px);

            color: #fff;
            pointer-events: none;

            user-select: none;

            .dark-mode & {
                color: #000;
            }

            display: flex;
            justify-content: center;
            align-items: center;

            clip-path: polygon(-100% 0%, 0% 0%, 0% 100%, -100% 100%);
            @media screen and (max-width: 1055px) {
                will-change: clip-path;
            }
        }

        & .after {
            position: absolute;
            top: -3px;
            right: -3px;

            width: calc(100% + 6px);
            height: calc(100% + 6px);

            background: #000;
            color: #fff;

            display: flex;
            justify-content: center;
            align-items: center;

            transform: translate(-100%, 0);

            transition-property: border-color, background-color, color;
            transition-duration: ${transitionOutDurPc}s;
            transition-timing-function: ${transitionOutEase};

            @media screen and (max-width: 1055px) {
                will-change: transform;

                transition-duration: ${transitionOutDurMobile}s;
            }

            .dark-mode & {
                background: #fff;
                color: #000;
            }
        }

        &.disabled {
            cursor: initial;
        }

        &.disabled .text,
        &.disabled .after,
        &.disabled .before {
            cursor: initial;
            border-color: #ccc;

            background: transparent;
            color: #ccc;

            &.text,
            &.after {
                transition-duration: ${transitionInDurPc}s;
                transition-timing-function: ${transitionInEase};
                @media screen and (max-width: 1055px) {
                    transition-duration: ${transitionInDurMobile}s;
                }
            }

            .dark-mode & {
                border-color: #333;
                color: #333;
            }

            &.after {
                background: #ccc;

                .dark-mode & {
                    background: #333;
                }
            }

            &.before {
                color: #f0f1f4;
                .dark-mode & {
                    color: #000;
                }
            }
        }

        &:not(.disabled):active .after {
            background: #222;

            .dark-mode & {
                background: #eee;
            }
        }

        &:not(.disabled):active .text {
            border-color: #222;
            .dark-mode & {
                border-color: #eee;
            }
        }
    }

    &.loading {
        button {
            color: #333 !important;
            border-color: #333 !important;
            .text {
                color: #333 !important;
                border-color: #333 !important;
            }

            & .after {
                background: #333 !important;
                border-color: #333 !important;
            }

            & .before {
                color: #333 !important;
            }
        }

        .border-clone {
            border-color: #333 !important;
        }
    }
`;

const StepManager = ({ type, disabled, onClick, back, next, loading }) => {
    const isSlideAnimating = useRecoilValue(selectors["getIsSlideAnimating"]);
    const isMobile = useRecoilValue(selectors["getIsMobileWidth"]);

    const typeMap = ["back", "next", "home"];
    const btn = useRef(null);
    const text = useRef(null);
    const after = useRef(null);
    const before = useRef(null);

    const [entered, setEntered] = useState(false);
    const [canClick, setCanClick] = useState(true);

    const isTouchScreen = "ontouchstart" in document.documentElement;

    const _handleStep = (e) => {
        if (btn.current.classList.contains("disabled") || !canClick) {
            e.preventDefault();
            return;
        }

        const swiperWrapper = document.querySelector(".swiper-wrapper");
        const currentActive = swiperWrapper.dataset.active * 1;
        const isMoving = swiperWrapper.dataset.moving;
        if (!isMoving && !isSlideAnimating) {
            if (type === 0 && currentActive > 0) {
                // Back
                back();
            } else if (type === 1) {
                // Next
                next();
            }
        }
        onClick(swiperWrapper, type);
    };

    const prevActive = useRef(1);

    const enterEvent = useCallback(() => {
        if (isTouchScreen && !canClick) {
            return;
        }

        if (!btn.current.classList.contains("disabled")) {
            const enterDur = isMobile ? 0.3 : 0.4;
            const enterEase = isMobile ? "" : "power2.out";

            const tl = gsap.timeline();
            gsap.killTweensOf(
                [after.current, before.current, text.current],
                "background, x, clipPath"
            );
            tl.addLabel("start")
                .to(
                    after.current,
                    {
                        x: "0",
                        duration: enterDur,
                        ease: enterEase,
                    },
                    "start"
                )
                .to(
                    before.current,
                    {
                        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                        duration: enterDur,
                        ease: enterEase,
                    },
                    "start"
                );

            return tl.totalDuration();
        }
    }, [isMobile, canClick, isTouchScreen]);

    const leaveEvent = useCallback(
        (force) => {
            if (
                (!btn.current.classList.contains("disabled") &&
                    !isTouchScreen) ||
                force
            ) {
                const leaveDur = isMobile ? 0.3 : 0.4;
                const leaveEase = isMobile ? "" : "power2.out";

                const tl = gsap.timeline();
                gsap.killTweensOf(
                    [after.current, before.current, text.current],
                    "background, x, clipPath"
                );

                tl.addLabel("start")
                    .to(
                        after.current,
                        {
                            x: "100%",
                            duration: leaveDur,
                            ease: leaveEase,
                        },
                        "start"
                    )
                    .to(
                        before.current,
                        {
                            clipPath:
                                "polygon(100% 0%, 200% 0%, 200% 100%, 100% 100%)",
                            duration: leaveDur,
                            ease: leaveEase,
                        },
                        "start"
                    )
                    .set(after.current, {
                        x: "-100%",
                    })
                    .set(before.current, {
                        clipPath:
                            "polygon(-100% 0%, 0% 0%, 0% 100%, -100% 100%)",
                    });
            }
        },
        [isMobile, isTouchScreen]
    );

    useEffect(() => {
        if (!isSlideAnimating && !isTouchScreen) {
            if (entered) {
                enterEvent();
            }
        }
    }, [isSlideAnimating]);

    useEffect(() => {
        if (isSlideAnimating) {
            setCanClick(false);
        } else {
            gsap.delayedCall(
                isMobile ? transitionInDurMobile : transitionInDurPc,
                () => {
                    setCanClick(true);
                }
            );
        }
    }, [isSlideAnimating, isMobile]);

    useEffect(() => {
        const swiper = document.querySelector(".swiper-wrapper");
        if (
            disabled &&
            swiper.dataset.active * 1 !== 0 &&
            swiper.dataset.active * 1 !== 1 &&
            swiper.dataset.active * 1 !== prevActive.current &&
            !isTouchScreen
        ) {
            leaveEvent(true);
        }

        const clickEvent = function (e) {
            if (btn.current.classList.contains("disabled")) {
                e.preventDefault();
                return;
            }
            if (isTouchScreen && !isSlideAnimating && canClick) {
                const enterDur = enterEvent();
                gsap.delayedCall(enterDur + 0.2, leaveEvent, [true]);
            } else if (!isTouchScreen && canClick) {
                leaveEvent(true);
            }
        };

        btn.current.addEventListener("click", clickEvent);

        const btnValue = btn.current;

        prevActive.current = swiper.dataset.active * 1;

        return () => {
            btnValue.removeEventListener("click", clickEvent);
        };
    }, [
        btn,
        disabled,
        canClick,
        onClick,
        type,
        isSlideAnimating,
        isMobile,
        isTouchScreen,
        leaveEvent,
        enterEvent,
    ]);

    const containerEnter = useCallback(() => {
        setEntered(true);
    }, []);

    const containerLeave = useCallback(() => {
        setEntered(false);
    }, []);

    return (
        <StyledContainer
            className={classNames(
                `${typeMap[type]}-container`,
                loading && "loading"
            )}
            onMouseEnter={containerEnter}
            onMouseOut={containerLeave}
        >
            <button
                className={classNames(
                    typeMap[type],
                    (disabled || isSlideAnimating) && "disabled"
                )}
                onClick={_handleStep}
                data-text={typeMap[type]}
                onMouseEnter={enterEvent}
                onMouseLeave={leaveEvent.bind(null, false)}
                ref={btn}
            >
                <span className="text" ref={text}>
                    <span className="inner-text">{typeMap[type]}</span>

                    <span className="after" ref={after}></span>
                    <span className="before" ref={before}>
                        <span className="before-text">{typeMap[type]}</span>
                    </span>
                </span>
            </button>
            <div className="border-clone"></div>
        </StyledContainer>
    );
};

export default StepManager;
