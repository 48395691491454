import React, { useEffect } from "react";

const AnimationWrapper = ({
    children,
    render,
    animationStart,
    startAnimation,
}) => {
    useEffect(() => {
        if (animationStart) startAnimation(children);
    }, [animationStart, startAnimation, children]);

    return render && <>{children}</>;
};

export default AnimationWrapper;
