import React from "react";
import styled from "@emotion/styled";
import MultiSelectChip from "../components/Chip/MultiSelectChip";
import { rangeProjectData } from "../data/formData";
import { duplicateOK, projectRequestData } from "../data/titleData";
import { useRecoilValue } from "recoil";
import { selectors } from "../recoil/atom";

const StyledContainer = styled.div`
    .style-wrap {
        .input-container {
            width: 100%;
        }
    }
`;

const ProjectRequest = () => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const { title } = projectRequestData[lang];
    return (
        <>
            <StyledContainer className="style-container" data-step="5">
                <div className="style-wrap">
                    <div className="title">
                        <div className="step">Step. 05</div>
                        <div>{title}</div>
                        <div className="sub-text">{duplicateOK[lang]}</div>
                    </div>
                    <div className="input-container">
                        <MultiSelectChip
                            selects={rangeProjectData}
                            coilKey="requestRangeProject"
                            startTabIndex={0}
                        />
                    </div>
                </div>
            </StyledContainer>
        </>
    );
};

export default React.memo(ProjectRequest);
