import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import StepManager from "../Button/StepManager";
import classNames from "classnames";
import ChoiseChip from "../Chip/ChoiseChip";
import { theme } from "../../theme";
import { useRecoilValue } from "recoil";
import { selectors } from "../../recoil/atom";
import useReset from "../../hooks/useReset";
import useValidation from "../../hooks/useValidation";
import useAnimation from "../../hooks/useAnimation";

const StyledStepContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: absolute;
    bottom: 0;
    z-index: 500;

    width: 100vw;
    padding: 0
        ${theme["normal"].paddingHorizontal.value +
        theme["normal"].paddingHorizontal.unit}
        ${theme["normal"].paddingVertical.value +
        theme["normal"].paddingVertical.unit};

    opacity: 0;
    visibility: hidden;

    user-select: none;

    @media screen and (max-width: 1055px) {
        padding: 0
            ${theme["1055"].paddingHorizontal.value +
            theme["1055"].paddingHorizontal.unit}
            ${theme["1055"].paddingVertical.value +
            theme["1055"].paddingVertical.unit};
    }

    &.current {
        z-index: 502;
    }

    &.current .chip-container.choise {
        pointer-events: auto;
    }

    .home-container {
        display: none;
        opacity: 0;
    }

    .choise-chip {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .chip-container {
            &.choise {
                pointer-events: none;
            }
            display: flex;
            align-items: center;

            margin: 0 35px;

            &.small-chip {
                margin: 0;

                & > div {
                    opacity: 0;
                    visibility: hidden;

                    .chip {
                        font-size: 0.54vw;

                        @media screen and (max-width: 1980px) {
                            font-size: 11px;
                        }
                    }
                }
            }

            @media screen and (max-width: 1055px) {
                margin: 0;
            }

            .choise-text {
                font-size: 0.7vw;
                font-weight: 400;
                line-height: 1.5;

                word-break: keep-all;
                word-wrap: break-word;

                opacity: 0;
                visibility: hidden;

                transform: translate(5.2vw, 0);

                @media screen and (max-width: 1980px) {
                    font-size: 14px;
                }

                @media screen and (max-width: 1055px) {
                    font-size: 12px;
                    letter-spacing: -0.04em;
                    line-height: 1.2;
                }
            }

            .divider-container {
                width: 5.2vw;
                margin: 0 25px;

                @media screen and (max-width: 1055px) {
                    flex: 1;
                    width: auto;
                }
                .divider {
                    display: block;
                    width: 100%;
                    height: 1px;

                    background: #000;

                    transform-origin: right;
                    transform: scaleX(0);
                }
            }
        }
        & > .chip-container {
            @media screen and (max-width: 1055px) {
                position: absolute;
                width: 50%;
                right: 0%;
                top: 0;
                transform: translate(0, calc(-100% - 20px));

                padding: 0 25px;

                justify-content: space-between;
            }

            @media screen and (max-width: 768px) {
                width: 60%;
            }

            @media screen and (max-width: 550px) {
                width: 100%;
            }
        }
    }
`;

const StepContainer = ({ choise }) => {
    const disable = useRecoilValue(selectors["getNextDisabled"]);
    const isSlideAnimating = useRecoilValue(selectors["getIsSlideAnimating"]);
    const apiLoading = useRecoilValue(selectors["getApiLoading"]);
    const apiSuccess = useRecoilValue(selectors["getApiSuccess"]);

    const [shadow, setShadow] = useState(false);

    const { nextActiveCondition } = useValidation();
    const { toHome } = useReset();
    const { next, back } = useAnimation();

    // 유효성 체크
    useEffect(() => {
        const swiper = document.querySelector(".swiper-wrapper");
        const active = swiper.dataset.active * 1;
        const type = swiper.dataset.type * 1;
        nextActiveCondition[type][active]();
    }, [nextActiveCondition]);

    useEffect(() => {
        const swiper = document.querySelector(".swiper-wrapper");
        const active = swiper.dataset.active * 1;
        const type = swiper.dataset.type * 1;

        if (!isSlideAnimating) {
            if (type === 0 && active === 6) {
                setShadow(true);
            }
        } else {
            setShadow(false);
        }
    }, [isSlideAnimating]);

    const handleActive = (swiper, type) => {
        if (!isSlideAnimating) {
            const active = swiper.dataset.active * 1;
            const requestType = swiper.dataset.type * 1;

            if (type !== 2) {
                nextActiveCondition[requestType][active]();
            } else {
                // Home
                toHome();
                nextActiveCondition[0][0]();
            }
        }
    };

    return (
        <StyledStepContainer
            className={classNames("step-container", shadow && "current")}
        >
            <StepManager
                type={0}
                onClick={handleActive}
                back={back}
                loading={apiLoading || apiSuccess}
            />
            <div className="choise-chip">
                <div
                    className={classNames("chip-container", choise && "choise")}
                >
                    <span className="choise-text">{choise}</span>
                    <div className="divider-container">
                        <span className="divider"></span>
                    </div>
                    <ChoiseChip
                        coilKey="scheduleAdjust"
                        choises={{
                            yes: {
                                text: { en: "Yes", kr: "Yes" },
                                id: "can-adjust",
                            },
                            no: {
                                text: { en: "No", kr: "No" },
                                id: "cant-adjust",
                            },
                        }}
                        small
                    />
                </div>
                <StepManager
                    type={1}
                    disabled={disable}
                    onClick={handleActive}
                    next={next}
                />
            </div>
            <StepManager type={2} onClick={handleActive} />
        </StyledStepContainer>
    );
};

export default StepContainer;
