import React from "react";
import styled from "@emotion/styled";
import DateSelector from "../components/UpDownSelector/DateSelector";
import { projectDueData } from "../data/titleData";
import { useRecoilValue } from "recoil";
import { selectors } from "../recoil/atom";

const StyledContainer = styled.div`
    .style-wrap {
        .title {
            .no-touch-screen & {
                @media screen and (max-height: 650px) {
                    margin-bottom: 36px;
                }
            }
        }

        .input-container {
            width: 100%;
            position: relative;

            @media screen and (max-width: 1055px) {
                top: -26px;
                max-width: 570px;
            }

            .divider {
                display: block;
                margin: 30px 0;

                height: 2px;
                width: 100%;
                background: rgba(0, 0, 0, 0.05);

                .current & {
                    box-shadow: 0px 2px 2px rgba(255, 255, 255, 0.32);
                }

                @media screen and (max-width: 1055px) {
                    margin: 17px 0;
                }
            }
        }
    }
`;

const ProjectDueView = () => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const { title } = projectDueData[lang];
    return (
        <>
            <StyledContainer className="style-container" data-step="6">
                <div className="style-wrap">
                    <div className="title">
                        <div className="step">Step. 06</div>
                        <div>{title}</div>
                    </div>
                    <div className="input-container">
                        <DateSelector coilKey="startDue" text="Start Date." />
                        <span className="divider"></span>
                        <DateSelector coilKey="endDue" text="End Date." />
                    </div>
                </div>
            </StyledContainer>
        </>
    );
};

export default React.memo(ProjectDueView);
