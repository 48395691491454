export const theme = {
    normal: {
        paddingVertical: {
            unit: "vh",
            value: 7.4,
        },
        paddingHorizontal: {
            unit: "vw",
            value: 4,
        },
    },
    1055: {
        paddingVertical: {
            unit: "px",
            value: 30,
        },
        paddingHorizontal: {
            unit: "px",
            value: 25,
        },
    },
    bigButtonDelay: 0.5,
    stepButtonDelay: {
        mobile: 1.2,
        pc: 1.8,
    },
    buttonOpDur: 0.4,
};
