import styled from "@emotion/styled";
import React from "react";
import { useRecoilState } from "recoil";
import { atoms } from "../../recoil/atom";

const StyledContainer = styled.div`
    margin-top: 50px;

    @media screen and (max-width: 1300px) {
        margin-top: 20px;
    }

    input {
        width: 100%;

        background: transparent;
        border-radius: 0px;
        border: none;
        outline: none;

        border-bottom: 2px solid #000;

        font-size: 18px;
        line-height: 1.45;
        letter-spacing: -0.03em;

        padding: 10px 0;

        @media screen and (max-width: 1500px) {
            font-size: 12px;
        }
        &::-webkit-:placeholder {
            color: #999;
        }
    }
`;

const NormalInput = ({ coilKey, ...rest }) => {
    const [value, setValue] = useRecoilState(atoms[coilKey]);

    const _handleChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <StyledContainer>
            <input {...rest} onChange={_handleChange} value={value} />
        </StyledContainer>
    );
};

export default NormalInput;
