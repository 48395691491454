import styled from "@emotion/styled";
import classNames from "classnames";
import React from "react";
import { useRecoilValue } from "recoil";
import { selectors } from "../../recoil/atom";

const StyledLoading = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 900;

    cursor: progress;

    &.success {
        cursor: default;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Loading = () => {
    const apiLoading = useRecoilValue(selectors["getApiLoading"]);
    const apiSuccess = useRecoilValue(selectors["getApiSuccess"]);

    return (
        (apiLoading || apiSuccess) && (
            <StyledLoading
                className={classNames(
                    "loading-container",
                    apiSuccess && "success"
                )}
            ></StyledLoading>
        )
    );
};

export default Loading;
