/**
 * 공통 데이터
 * Common Data
 */
export const requestTypeTitle = {
    en: {
        title: (
            <>
                EN)어떤 업무로 <br />
                방문해주셨나요?
            </>
        ),
    },
    kr: {
        title: (
            <>
                어떤 업무로 <br />
                방문해주셨나요?
            </>
        ),
    },
};

export const requesterData = {
    en: {
        title: (
            <>
                EN)의뢰하시는 분의 <br />
                정보를 적어주세요.
            </>
        ),
        namePlaceholder: "EN)성함",
        companyPlaceholder: "EN)회사/기업",
        emailPlaceholder: "EN)이메일",
        contactPlaceholder: "EN)연락처",
        departmentPlaceholder: "EN)부서(선택)",
        dutyPlaceholder: "EN)직함(선택)",
        companyErrorText: "EN)회사명이 중복됩니다.",
        emailErrorText: "EN)올바르지 않은 이메일 형식입니다.",
        contactErrorText: "EN)올바르지 않은 연락처 형식입니다.",
    },
    kr: {
        title: (
            <>
                의뢰하시는 분의 <br />
                정보를 적어주세요.
            </>
        ),
        namePlaceholder: "성함",
        companyPlaceholder: "회사/기업",
        emailPlaceholder: "이메일",
        contactPlaceholder: "연락처",
        departmentPlaceholder: "부서(선택)",
        dutyPlaceholder: "직함(선택)",
        companyErrorText: "회사명이 중복됩니다.",
        emailErrorText: "올바르지 않은 이메일 형식입니다.",
        contactErrorText: "올바르지 않은 연락처 형식입니다.",
    },
};

export const additionalInfoData = {
    en: {
        title: (
            <>
                EN)저희가 더 알아야 <br />
                하는 내용이 있다면 <br className="no-mobile" />
                알려주세요.
            </>
        ),
        choiseTxt: "EN)(선택 사항)",
        textareaPlaceholder:
            "EN)추가 전달 내용이 있으시면 알려주세요.\n(선택사항)",
        filePlaceholder: "EN)파일 첨부하기",
        uploadPlaceholder:
            "EN)제안요청서 또는 기타 파일이 있다면 전달 부탁드립니다.",
        uploadedPlaceholder: (fileLength) => `EN)${fileLength}개의 파일`,
        msg1: "EN)최대 파일 첨부 갯수는 3개 입니다.",
        msg2: "EN)첨부파일은 최대 20MB까지 업로드 가능합니다. 파일 용량을 다시 한 번 확인 해주세요.",
        msg3: "EN)업로드 가능한 확장자는 pdf, ppt, word, exel, zip, jpg, jpeg, png 입니다. 파일 확장자를 다시 확인 해주세요.",
    },
    kr: {
        title: (
            <>
                저희가 더 알아야 <br />
                하는 내용이 있다면 <br className="no-mobile" />
                알려주세요.
            </>
        ),
        choiseTxt: "(선택 사항)",
        textareaPlaceholder:
            "추가 전달 내용이 있으시면 알려주세요.\n(선택사항)",
        filePlaceholder: "파일 첨부하기",
        uploadPlaceholder:
            "제안요청서 또는 기타 파일이 있다면 전달 부탁드립니다.",
        uploadedPlaceholder: (fileLength) => `${fileLength}개의 파일`,
        msg1: "최대 파일 첨부 갯수는 3개 입니다.",
        msg2: "첨부파일은 최대 20MB까지 업로드 가능합니다. 파일 용량을 다시 한 번 확인 해주세요.",
        msg3: "업로드 가능한 확장자는 pdf, ppt, word, exel, zip, jpg, jpeg, png 입니다. 파일 확장자를 다시 확인 해주세요.",
    },
};

export const checkPrivacyData = {
    en: {
        title: (
            <>
                EN)
                <button className="show-privacy">개인정보 보호정책</button>에
                동의합니다.
            </>
        ),
        modalTitle: "EN)개인정보 보호 정책",
        modalContent: (
            <>
                <div className="modal-body-item">
                    <div className="modal-title-sub">
                        EN)개인정보 수집 이용에 대한 동의
                    </div>
                    <div className="modal-body-content">
                        <div>
                            의뢰인님의 소중한 개인정보는 다음과 같은 정책에 따라
                            수집 및 이용됩니다.
                        </div>
                        <div>
                            저희 플러스엑스에서는 해당 목적에 연관되는
                            개인정보만을 수집하며, 수집된 정보를 투명하고
                            안전하게 보호 관리할 것을 약속합니다. 이에 개인정보
                            수집 및 이용에 대한 동의를 구합니다.
                        </div>
                    </div>
                </div>
                <div className="modal-body-item">
                    <div className="modal-title-sub">
                        개인정보의 수집·이용 목적
                    </div>
                    <div className="modal-body-content">
                        <div>
                            의뢰인님의 프로젝트에 대한 예산, 기간, 개발 방법
                            등의 문의에 대한 정보가 보다 정확한 답변을 위해
                            수집됩니다.
                        </div>
                    </div>
                </div>
                <div className="modal-body-item">
                    <div className="modal-title-sub">수집항목</div>
                    <div className="modal-body-content">
                        <div>
                            필수 항목 : 회사/기업명, 성명, 이메일, 휴대전화,
                            프로젝트명, 예산, 작업 기간
                        </div>
                        <div>선택 항목 : 부서, 직책, 첨부파일, 의뢰 내용</div>
                    </div>
                </div>
                <div className="modal-body-item">
                    <div className="modal-title-sub">보유이용기간</div>
                    <div className="modal-body-content">
                        <div>
                            원칙적으로, 개인정보 수집 및 이용목적이 달성된
                            후에는 해당 정보를 지체 없이 파기합니다.
                        </div>
                        <div>
                            단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간
                            동안 보존합니다.
                        </div>
                        <div>
                            보존 이유 : 의뢰인님의 동의를 통한 인재 정보 유지
                        </div>
                        <div>보존 기간 : 정보 삭제 요청 시까지</div>
                    </div>
                </div>
                <div className="modal-body-item">
                    <div className="modal-title-sub">
                        동의를 거부할 권리 및 동의를 거부할 경우의 이익
                    </div>
                    <div className="modal-body-content">
                        <div>
                            위 개인정보 중 필수적 정보의 수집•이용에 관한 동의는
                            의뢰를 위하여 필수적이므로, 위 사항에 동의하셔야만
                            의뢰가 가능합니다.
                        </div>
                    </div>
                </div>
            </>
        ),
    },
    kr: {
        title: (
            <>
                <button className="show-privacy">개인정보 보호정책</button>에
                동의합니다.
            </>
        ),
        modalTitle: "개인정보 보호 정책",
        modalContent: (
            <>
                <div className="modal-body-item">
                    <div className="modal-title-sub">
                        개인정보 수집 이용에 대한 동의
                    </div>
                    <div className="modal-body-content">
                        <div>
                            의뢰인님의 소중한 개인정보는 다음과 같은 정책에 따라
                            수집 및 이용됩니다.
                        </div>
                        <div>
                            저희 플러스엑스에서는 해당 목적에 연관되는
                            개인정보만을 수집하며, 수집된 정보를 투명하고
                            안전하게 보호 관리할 것을 약속합니다. 이에 개인정보
                            수집 및 이용에 대한 동의를 구합니다.
                        </div>
                    </div>
                </div>
                <div className="modal-body-item">
                    <div className="modal-title-sub">
                        개인정보의 수집·이용 목적
                    </div>
                    <div className="modal-body-content">
                        <div>
                            의뢰인님의 프로젝트에 대한 예산, 기간, 개발 방법
                            등의 문의에 대한 정보가 보다 정확한 답변을 위해
                            수집됩니다.
                        </div>
                    </div>
                </div>
                <div className="modal-body-item">
                    <div className="modal-title-sub">수집항목</div>
                    <div className="modal-body-content">
                        <div>
                            필수 항목 : 회사/기업명, 성명, 이메일, 휴대전화,
                            프로젝트명, 예산, 작업 기간
                        </div>
                        <div>선택 항목 : 부서, 직책, 첨부파일, 의뢰 내용</div>
                    </div>
                </div>
                <div className="modal-body-item">
                    <div className="modal-title-sub">보유이용기간</div>
                    <div className="modal-body-content">
                        <div>
                            원칙적으로, 개인정보 수집 및 이용목적이 달성된
                            후에는 해당 정보를 지체 없이 파기합니다.
                        </div>
                        <div>
                            단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간
                            동안 보존합니다.
                        </div>
                        <div>
                            보존 이유 : 의뢰인님의 동의를 통한 인재 정보 유지
                        </div>
                        <div>보존 기간 : 정보 삭제 요청 시까지</div>
                    </div>
                </div>
                <div className="modal-body-item">
                    <div className="modal-title-sub">
                        동의를 거부할 권리 및 동의를 거부할 경우의 이익
                    </div>
                    <div className="modal-body-content">
                        <div>
                            위 개인정보 중 필수적 정보의 수집•이용에 관한 동의는
                            의뢰를 위하여 필수적이므로, 위 사항에 동의하셔야만
                            의뢰가 가능합니다.
                        </div>
                    </div>
                </div>
            </>
        ),
    },
};

export const checkInformationData = {
    en: {
        requestType: "EN)의뢰 유형",
        requester: "EN)의뢰하시는 분 정보",
        name: "EN)성함",
        department: "EN)부서(선택)",
        duty: "EN)직책(선택)",
        company: "EN)회사/기업",
        email: "EN)이메일",
        contact: "EN)연락처",
        requestName: "EN)의뢰명",
        requestKind: "EN)업무종류",
        additionalDescription: "EN)추가 설명",
        attached: "EN)첨부 파일",
        projectName: "EN)프로젝트명",
        projectRange: "EN)업무범위",
        projectKind: "EN)개발 유형",
        projectDue: "EN)프로젝트 일정",
        projectPrice: "EN)예산",
        projectDueContent: (
            year,
            month,
            dueText,
            endYear,
            endMonth,
            endDueText
        ) => `EN)${year}년 ${month}월
              ${dueText[1]} ~ ${endYear}년
              ${endMonth}월 ${endDueText[1]}`,
    },
    kr: {
        requestType: "의뢰 유형",
        requester: "의뢰하시는 분 정보",
        name: "성함",
        department: "부서(선택)",
        duty: "직책(선택)",
        company: "회사/기업",
        email: "이메일",
        contact: "연락처",
        requestName: "의뢰명",
        requestKind: "업무종류",
        additionalDescription: "추가 설명",
        attached: "첨부 파일",
        projectName: "프로젝트명",
        projectRange: "업무범위",
        projectKind: "개발 유형",
        projectDue: "프로젝트 일정",
        projectPrice: "예산",
        projectDueContent: (
            year,
            month,
            dueText,
            endYear,
            endMonth,
            endDueText
        ) => `${year}년 ${month}월
            ${dueText[1]} ~ ${endYear}년
            ${endMonth}월 ${endDueText[1]}`,
    },
};

export const sendViewData = {
    en: {
        title: (
            <>
                EN)Contact me <br />
                anytime.
            </>
        ),
        smallText: "EN)입력하신 내용을 PlusX에 전달하시겠습니까?",
        basicErrorText: "EN)오류가 발생했습니다.",
        phoneErrorText: "EN)올바른 전화번호를 입력해주세요.",
    },
    kr: {
        title: (
            <>
                Contact me <br />
                anytime.
            </>
        ),
        smallText: "입력하신 내용을 PlusX에 전달하시겠습니까?",
        basicErrorText: "오류가 발생했습니다.",
        phoneErrorText: "올바른 전화번호를 입력해주세요.",
    },
};

export const finalViewData = {
    en: {
        confirm: (requester, requestType, projectName) => (
            <>
                <div className="requester bold">
                    EN){requester.name}
                    {requester.duty.trim() === "님" && ""}
                    {requester.duty && ` ${requester.duty}`}
                    님,
                </div>
                <div className="confirm">
                    <p>
                        <span className="bold">
                            {requestType !== "etc"
                                ? projectName.projectName
                                : projectName.requestName}
                        </span>{" "}
                        건이 정상적으로 접수되었습니다.
                    </p>
                    <p>
                        의뢰하신 내용은{" "}
                        <span className="bold">{requester.email}</span>로
                        발송되었습니다.
                    </p>
                    <p>담당자 검토 후 빠른 시일 내로 회신 드리겠습니다.</p>
                    <p>감사합니다.</p>
                </div>
            </>
        ),
    },
    kr: {
        confirm: (requester, requestType, projectName) => (
            <>
                <div className="requester bold">
                    {requester.name}
                    {requester.duty.trim() === "님" && ""}
                    {requester.duty && ` ${requester.duty}`}
                    님,
                </div>
                <div className="confirm">
                    <p>
                        <span className="bold">
                            {requestType !== "etc"
                                ? projectName.projectName
                                : projectName.requestName}
                        </span>{" "}
                        건이 정상적으로 접수되었습니다.
                    </p>
                    <p>
                        의뢰하신 내용은{" "}
                        <span className="bold">{requester.email}</span>로
                        발송되었습니다.
                    </p>
                    <p>담당자 검토 후 빠른 시일 내로 회신 드리겠습니다.</p>
                    <p>감사합니다.</p>
                </div>
            </>
        ),
    },
};

/**
 * 프로젝트 데이터
 * Project Data
 */
export const projectNameData = {
    en: {
        title: (
            <>
                EN)의뢰하시는 <br />
                프로젝트명을 <br className="no-mobile" />
                적어주세요.
            </>
        ),
        namePlaceholder: "EN)프로젝트 명",
        projectErrorText: "EN)프로젝트 명이 중복됩니다.",
    },
    kr: {
        title: (
            <>
                의뢰하시는 <br />
                프로젝트명을 <br className="no-mobile" />
                적어주세요.
            </>
        ),
        namePlaceholder: "프로젝트 명",
        projectErrorText: "프로젝트 명이 중복됩니다.",
    },
};

export const projectRangeData = {
    en: {
        title: (
            <>
                EN)해당 프로젝트의 <br />
                업무 범위를 <br className="no-mobile" />
                선택해주세요.
            </>
        ),
    },
    kr: {
        title: (
            <>
                해당 프로젝트의 <br />
                업무 범위를 <br className="no-mobile" />
                선택해주세요.
            </>
        ),
    },
};

export const duplicateOK = {
    en: "EN)(중복 선택 가능)",
    kr: "(중복 선택 가능)",
};

export const projectRequestData = {
    en: {
        title: (
            <>
                EN)저희 PlusX에 <br />
                어떤 부분을 의뢰하고 <br className="no-mobile" />
                싶으신가요?
            </>
        ),
    },
    kr: {
        title: (
            <>
                저희 PlusX에 <br />
                어떤 부분을 의뢰하고 <br className="no-mobile" />
                싶으신가요?
            </>
        ),
    },
};
export const projectDueData = {
    en: {
        title: (
            <>
                EN)의뢰하시는 <br className="no-mobile" />
                프로젝트의
                <br className="no-pc" /> 예상 <br className="no-mobile" />
                일정을 알려주세요.
            </>
        ),
    },
    kr: {
        title: (
            <>
                의뢰하시는 <br className="no-mobile" />
                프로젝트의
                <br className="no-pc" /> 예상 <br className="no-mobile" />
                일정을 알려주세요.
            </>
        ),
    },
};

export const duesTxt = {
    en: ["EN)월초", "EN)월중", "EN)월말"],
    kr: ["월초", "월중", "월말"],
};

export const adjustScheduleText = {
    en: "EN)Plus X 일정에 맞추어 일정을 조율할 수 있나요?",
    kr: (
        <>
            Plus X 일정에 맞추어
            <br className="no-pc" /> 일정을 조율할 수 있나요?
        </>
    ),
};

export const projectPriceData = {
    en: {
        title: (
            <>
                EN) 해당 프로젝트의 <br />
                대략적인 예산을 <br className="no-mobile" />알 수 있을까요?
            </>
        ),
    },
    kr: {
        title: (
            <>
                해당 프로젝트의 <br />
                대략적인 예산을 <br className="no-mobile" />알 수 있을까요?
            </>
        ),
    },
};

/**
 * 의뢰 데이터
 * Request Data
 */
export const requestNameData = {
    en: {
        title: (
            <>
                EN)의뢰명을 <br />
                알려주세요.
            </>
        ),
        namePlaceholder: "EN)의뢰명",
        requestErrorText: "EN)의뢰명이 중복됩니다.",
    },
    kr: {
        title: (
            <>
                의뢰명을 <br />
                알려주세요.
            </>
        ),
        namePlaceholder: "의뢰명",
        requestErrorText: "의뢰명이 중복됩니다.",
    },
};

export const requestKindData = {
    en: {
        title: (
            <>
                EN) 해당 의뢰의 <br />
                업무 종류를 <br className="no-mobile" />
                선택해주세요.
            </>
        ),
        etcRequestPlaceholder: "EN) 의뢰 종류를 작성해주세요.",
    },
    kr: {
        title: (
            <>
                해당 의뢰의 <br />
                업무 종류를 <br className="no-mobile" />
                선택해주세요.
            </>
        ),
        etcRequestPlaceholder: "의뢰 종류를 작성해주세요.",
    },
};

// Alert
export const alertText = {
    en: "EN)확인",
    kr: "확인",
};
