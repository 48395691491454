import React, { useLayoutEffect } from "react";
import styled from "@emotion/styled";
import { theme } from "../theme";
import gsap from "gsap";
import useAnimation from "../hooks/useAnimation";

const StyledMainContainer = styled.div`
    padding: 250px 0;
    width: 100vw;
    height: 100%;

    flex-shrink: 0;

    &.style-container {
        display: block !important;
        pointer-events: none;
    }

    .view-container & {
        margin-right: 0;
    }

    .main-wrap {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;

        padding: 0
            ${theme["normal"].paddingHorizontal.value * 2 +
            theme["normal"].paddingHorizontal.unit};

        @media screen and (max-width: 1055px) {
            padding: 0 25px;
        }

        .line-container {
            position: absolute;
            width: 50%;

            .line {
                position: absolute;
                width: 100%;
                height: 2px;

                left: 0;

                background: #000;

                transform-origin: left;
                transform: scale(0, 1);

                z-index: -1;
                @media screen and (max-width: 1055px) {
                    display: none;
                }
            }
        }

        .title {
            position: relative;
            font-size: 12vw;
            font-weight: 700;
            line-height: 0.9;

            overflow: hidden;

            padding-left: 0.25em;

            @media screen and (max-width: 1055px) {
                font-size: 85px;
                letter-spacing: -0.01em;

                padding: 0;
            }

            @media screen and (max-width: 375px) {
                font-size: 75px;
                line-height: 68px;
            }

            .title-text {
                white-space: nowrap;
                position: relative;
                .point {
                    position: relative;
                    margin-left: 1vw;

                    display: inline-block;
                    color: #ff0000;
                }

                .title-text-line {
                    overflow: hidden;

                    display: inline-block;
                    position: relative;

                    width: 100%;

                    .title-text-char {
                        transform: translate(3vw, 120%) rotate(14deg);
                        display: inline-block;
                        position: relative;

                        opacity: 0;
                        visibility: hidden;

                        will-change: transform;
                    }

                    .point {
                        transform: translate(100%, 40%);
                        left: -100%;

                        opacity: 0;
                        visibility: hidden;
                        will-change: transform;
                        .title-text-char {
                            transform: initial;
                        }
                    }
                }

                &.title-text-1 {
                    top: 0.005em;
                }

                &.title-text-2 {
                    top: -0.005em;
                }
            }
        }
    }
`;

const MainView = () => {
    const { mainIntroAnimation } = useAnimation();

    useLayoutEffect(() => {
        gsap.delayedCall(0.8, mainIntroAnimation);
    }, []);

    return (
        <>
            <StyledMainContainer
                className="style-container main-container"
                data-step={0}
            >
                <div className="main-wrap">
                    <div className="title">
                        <div className="title-wrap">
                            <div className="title-text title-text-1">
                                <div className="title-text-line">
                                    <div className="title-text-char">P</div>
                                    <div className="title-text-char">l</div>
                                    <div className="title-text-char">u</div>
                                    <div className="title-text-char">s</div>
                                    <div className="title-text-char X">X</div>
                                </div>
                            </div>
                            <div className="title-text title-text-2">
                                <div className="title-text-line">
                                    <div className="title-text-char">C</div>
                                    <div className="title-text-char">o</div>
                                    <div className="title-text-char">n</div>
                                    <div className="title-text-char">t</div>
                                    <div className="title-text-char">a</div>
                                    <div className="title-text-char">c</div>
                                    <div className="title-text-char">t</div>
                                    <span className="point">.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </StyledMainContainer>
        </>
    );
};

export default React.memo(MainView);
