import styled from "@emotion/styled";
import React from "react";
import Info from "../components/Info";

const StyledContainer = styled.div`
    .style-wrap {
        .title {
            font-size: 5.2vw;
            color: #fff;

            transition: color 0.5s;

            @media screen and (max-width: 1055px) {
                line-height: 1.5;
                font-size: 22px;

                height: 17vh;

                .no-touch-screen & {
                    min-height: 14vh;
                    height: max-content;
                }

                .touch-screen & {
                    height: 14vh;
                }
            }
        }

        .input-container {
            height: 50vh;
            min-height: 50vh;
            max-height: 50vh;
            border-radius: 34px;

            max-width: 868px;

            padding: 50px;

            background: #fff;

            @media screen and (max-width: 1055px) {
                max-width: unset;
                width: 100%;
                padding: 30px 25px;
                min-height: unset;
                height: calc(100% - 66px - 8vh);
                border-radius: 17px;

                max-height: unset;
            }

            @media screen and (max-width: 375px) {
                height: calc(100% - 135px);
            }

            .info-container {
                padding-right: 30px;

                @media screen and (max-width: 1055px) {
                    padding-right: 20px;
                }
            }
        }
    }
`;

const CheckInformationView = () => {
    return (
        <StyledContainer className="style-container" data-info>
            <div className="style-wrap">
                <div className="title">
                    <div>
                        Check <br />
                        Your <br className="no-mobile" />
                        information
                    </div>
                </div>
                <div className="input-container">
                    <Info />
                </div>
            </div>
        </StyledContainer>
    );
};

export default React.memo(CheckInformationView);
