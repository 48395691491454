import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useRecoilState, useRecoilValue } from "recoil";
import { atoms, formatMonth, selectors } from "../../recoil/atom";
import {
    getFastDateFromNow,
    getFastDateFromStart,
    getUpdatedDate,
} from "../../lib/date";
import classNames from "classnames";

const btnPadding = 6;

const Due = styled.div`
    display: flex;
    align-items: center;

    font-size: 5vw;

    margin: 0 1.5vw;
    position: relative;

    &.disabled {
        .btn-container {
            pointer-events: none;
        }

        &.year,
        &.month,
        &.due {
            .btn-container .up {
                pointer-events: auto;
            }

            .down {
                & .after {
                    background-color: #fff;
                    color: #ccc;
                }
            }
        }

        &.both {
            .btn-container .up {
                pointer-events: none;
                & .after {
                    background-color: #fff;
                    color: #ccc;
                }
            }
        }
    }

    @media screen and (max-width: 1055px) {
        margin: 0 5px;
        ${"" /* font-size:30px; */}
        font-size: 50px;

        padding-right: calc(0.8em + 10px);
    }

    @media screen and (max-width: 620px) {
        font-size: 42px;
    }

    @media screen and (max-width: 500px) {
        font-size: 30px;
    }

    &:nth-of-type(1) {
        margin-left: 0px;
    }

    &:nth-last-of-type(1) {
        margin-right: 0;
    }

    &.year label {
        min-width: 13.1vw;
    }
    &.month label {
        min-width: 7vw;
    }

    &.due label {
        min-width: 10.5vw;
    }

    @media screen and (max-width: 1055px) {
        &.year label {
            min-width: 90px;
        }
        &.month label {
            min-width: 50px;
        }

        &.due label {
            min-width: 80px;
        }
    }

    @media screen and (max-width: 500px) {
        &.year label {
            min-width: 70px;
        }
        &.month label {
            min-width: 30px;
        }

        &.due label {
            min-width: 60px;
        }
    }

    label {
        font-weight: 700;
        line-height: 1.1;
        white-space: nowrap;
    }

    .btn-container {
        display: flex;
        flex-direction: column;

        height: 1em;
        width: 0.5em;

        max-height: 115px;
        max-width: 57px;

        min-width: 32px;
        min-height: 68px;

        transition-property: background-color, opacity, box-shadow;
        transition-duration: 0.3s;

        border-radius: 0.5em;

        @media screen and (max-width: 1055px) {
            position: absolute;
            right: 0;
            margin: 0;
        }

        @media screen and (max-width: 620px) {
            min-width: auto;
            min-height: auto;

            max-width: auto;
            max-height: auto;

            width: 28px;
            height: 56px;
        }

        @media screen and (max-width: 500px) {
            width: 24px;
            height: 48px;
        }

        .current & {
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
        }

        &:active {
            box-shadow: none;
        }

        button {
            height: calc(50% + ${btnPadding}px);
            border: none;
            font-size: inherit;

            position: relative;
            top: ${-btnPadding}px;

            background: transparent;

            cursor: pointer;

            width: calc(100% + ${btnPadding * 2}px);
            padding: 0 ${btnPadding}px;
            left: -${btnPadding}px;

            flex: 1 0 auto;

            & .after {
                content: "";
                position: absolute;
                top: auto;
                left: 50%;

                transform-origin: center;
                transform: translate(-50%, -50%);

                width: calc(100% - ${btnPadding * 2}px);
                height: calc(100% - ${btnPadding}px);

                font-size: inherit;
                background-color: #fff;
                color: #000;
                border-radius: 0.5em 0.5em 0 0;

                transition-property: background-color, opacity, color;
                transition-duration: 0.3s;

                .mask {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;

                    -webkit-mask: url(/svg/date-button.svg) no-repeat center;
                    mask: url(/svg/date-button.svg) no-repeat center;
                    mask-size: 40%;

                    color: inherit;
                    background-color: currentColor;
                }
            }

            &:hover .after {
                background-color: #fafafa;
            }

            &.up {
                padding-top: ${btnPadding}px;

                border-radius: 0.2em 0.2em 0 0;
                @media screen and (max-width: 1055px) {
                    border-radius: 0.6em 0.6em 0 0;
                }
                & .before {
                    content: "";
                    position: absolute;
                    bottom: -0.5px;

                    height: 1px;
                    width: calc((100% - ${btnPadding * 2}px) * 35 / 100);

                    left: 50%;
                    transform: translate(-50%, 0);
                    background: #f5f5f5;
                }
            }
            &.down {
                padding-bottom: ${btnPadding}px;

                border-radius: 0 0 0.2em 0.2em;
                @media screen and (max-width: 1055px) {
                    border-radius: 0 0 1em 1em;
                }
                & .after {
                    transform: translate(-50%, -50%) rotate(180deg);
                }
            }
        }
    }
`;

const UpDownSelector = ({ coilKey, name }) => {
    const lang = useRecoilValue(selectors["getLanguage"]);

    const pair = {
        startDue: "endDue",
        endDue: "startDue",
    };
    const [due, setDue] = useRecoilState(atoms[coilKey]);
    const [pairDue, setPairDue] = useRecoilState(atoms[pair[coilKey]]);

    const [disabled, setDisabled] = useState(false);
    const [both, setBoth] = useState(false);

    const btnContainer = useRef(null);

    const _handleUpDown = (upDown) => {
        const [
            updatedYear,
            updatedMonth,
            updatedDue,
            pairYear,
            pairMonth,
            pairDueType,
        ] = getUpdatedDate(name, coilKey, upDown, due, pairDue);

        setDue({
            ...due,
            year: updatedYear,
            month: updatedMonth,
            due: updatedDue,
        });

        setPairDue({
            ...pairDue,
            year: pairYear,
            month: pairMonth,
            due: pairDueType,
        });
    };

    useEffect(() => {
        let yearLimit, monthLimit, dueLimit;
        if (coilKey === "startDue") {
            [yearLimit, monthLimit, dueLimit] = getFastDateFromNow();
        } else if (coilKey === "endDue") {
            [yearLimit, monthLimit, dueLimit] = getFastDateFromStart();
        }

        if (name === "year") {
            if (due[name] === yearLimit) {
                setDisabled(true);
            } else {
                setDisabled(false);
            }
        }

        if (name === "month") {
            if (due["year"] === yearLimit && due[name] === monthLimit) {
                setDisabled(true);
            } else {
                setDisabled(false);
            }
        }

        if (name === "due") {
            if (
                due["year"] === yearLimit &&
                due["month"] === monthLimit &&
                due[name] === dueLimit &&
                due[name] !== 0
            ) {
                setDisabled(true);

                if (due[name] === 2) {
                    setBoth(true);
                } else {
                    setBoth(false);
                }
            } else {
                setDisabled(false);
            }
        }
    }, [due, coilKey, name]);

    return (
        <Due
            className={classNames(name, disabled && "disabled", both && "both")}
        >
            <label>
                {name === "due"
                    ? due.dues[lang][due[name]]
                    : formatMonth(due[name])}
            </label>
            <div className="btn-container" ref={btnContainer}>
                <button className="up" onClick={() => _handleUpDown(1)}>
                    <span className="before"></span>
                    <span className="after">
                        <span className="mask"></span>
                    </span>
                </button>
                <button className="down" onClick={() => _handleUpDown(-1)}>
                    <span className="before"></span>
                    <span className="after">
                        <span className="mask"></span>
                    </span>
                </button>
            </div>
        </Due>
    );
};

export default React.memo(UpDownSelector);
