import gsap from "gsap";
import { useCallback, useEffect, useMemo, useRef } from "react";

const useTab = (currentActive) => {
    const tab = useRef(0);

    const mainTabController = useCallback(() => {
        // Main
        document.querySelector("button.next").focus({
            preventScroll: true,
        });
    }, []);

    const tabController = useCallback(
        (tabList, noNext, noBack) => {
            if (!noNext) tabList.push(document.querySelector("button.next"));
            if (!noBack) tabList.unshift(document.querySelector("button.back"));

            if (tab.current >= tabList.length) {
                tab.current = 0;
            }

            tabList[tab.current].focus({
                preventScroll: true,
            });
        },
        [tab]
    );

    // 공통 탭 컨트롤러들
    const requestTypeTabController = useCallback(
        () =>
            tabController([
                document.querySelector("#request-project"),
                document.querySelector("#request-etc"),
            ]),
        [tabController]
    );

    const requesterTabController = useCallback(
        () =>
            tabController([
                document.querySelector("input[name=name]"),
                document.querySelector("input[name=company]"),
                document.querySelector("input[name=email]"),
                document.querySelector("input[name=contact]"),
                document.querySelector("input[name=department]"),
                document.querySelector("input[name=duty]"),
            ]),
        [tabController]
    );

    const additionalInfoTabController = useCallback(
        () =>
            tabController([
                document.querySelector("textarea"),
                document.querySelector("#additionalFile"),
            ]),
        [tabController]
    );

    const privacyCheckTabController = useCallback(
        () =>
            tabController(
                [
                    document.querySelector("#privacy"),
                    document.querySelector("button[data-text=check]"),
                ],
                true
            ),
        [tabController]
    );
    const privacyInfoTabController = useCallback(
        () => tabController([]),
        [tabController]
    );
    const sendTabController = useCallback(
        () => tabController([document.querySelector("button[data-text=send]")]),
        [tabController]
    );
    const finalTabController = useCallback(
        () =>
            tabController([document.querySelector("button.home")], true, true),
        [tabController]
    );

    const tabControl = useMemo(
        () => [
            [
                () => mainTabController(),
                requestTypeTabController,
                requesterTabController,
                // 프로젝트 명
                () =>
                    tabController([
                        document.querySelector("input[name=projectName]"),
                    ]),
                // 프로젝트 업무 범위
                () =>
                    tabController([
                        document.querySelector("#brand-strategy"),
                        document.querySelector("#brand-name-slogan"),
                        document.querySelector("#concept-design"),
                        document.querySelector("#brand-identity-design"),
                        document.querySelector("#range-ui-web"),
                        document.querySelector("#range-ui-app"),
                        document.querySelector("#range-ui-backend"),
                        document.querySelector("#range-ui-frontend"),
                    ]),
                // 의뢰 종류
                () =>
                    tabController([
                        document.querySelector("#range-project-consulting"),
                        document.querySelector("#range-project-new"),
                        document.querySelector("#range-project-improve"),
                        document.querySelector("#range-project-manage"),
                        document.querySelector("#range-project-etc"),
                    ]),
                // 예상 일정
                () =>
                    tabController([
                        document.querySelector("#can-adjust"),
                        document.querySelector("#cant-adjust"),
                    ]),
                // 가격
                () =>
                    tabController([
                        document.querySelector("#under-5"),
                        document.querySelector("#between-5-10"),
                        document.querySelector("#between-10-20"),
                        document.querySelector("#between-20-30"),
                        document.querySelector("#between-30-40"),
                        document.querySelector("#between-40-50"),
                        document.querySelector("#over-50"),
                    ]),
                // 공통
                additionalInfoTabController,
                privacyCheckTabController,
                privacyInfoTabController,
                sendTabController,
                finalTabController,
            ],
            [
                () => mainTabController(),
                requestTypeTabController,
                requesterTabController,
                // 의뢰명
                () =>
                    tabController([
                        document.querySelector("input[name=requestName]"),
                    ]),
                // 의뢰 업무 종류
                () =>
                    tabController([
                        document.querySelector("#range-kind-ad"),
                        document.querySelector("#range-kind-interview"),
                        document.querySelector("#range-kind-seminal"),
                        document.querySelector("#range-kind-marketing"),
                        document.querySelector("#range-kind-corp"),
                        document.querySelector("#range-kind-etc"),
                    ]),
                // 공통
                additionalInfoTabController,
                privacyCheckTabController,
                privacyInfoTabController,
                sendTabController,
                finalTabController,
            ],
        ],
        [
            mainTabController,
            tabController,
            requestTypeTabController,
            requesterTabController,
            additionalInfoTabController,
            privacyCheckTabController,
            privacyInfoTabController,
            sendTabController,
            finalTabController,
        ]
    );

    const setTab = useCallback((i) => {
        tab.current = i;
    }, []);

    const addTabFrom = useCallback((amount) => {
        tab.current += amount;
    }, []);

    useEffect(() => {
        gsap.utils
            .toArray(
                document.querySelectorAll(
                    `[data-step='${currentActive}'] [data-tabindex]`
                )
            )
            .forEach((item) => {
                item.addEventListener("click", function (e) {
                    if (this.dataset.tabindex) {
                        tab.current = this.dataset.tabindex * 1;
                    }
                });
            });
    }, [currentActive]);

    return { tabControl, tab, setTab, addTabFrom };
};

export default useTab;
